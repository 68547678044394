import { isString } from 'lodash-es';

const PLATFORM_WHATSAPP = 'WHATSAPP';

app.directive('whatsappMessengerCheckbox', [
  '$q',
  'mainConfig',
  'benchatService',
  function ($q, mainConfig, benchatService) {
    return {
      restrict: 'E',
      template: `
      <div class="messenger-checkbox">
        <input
          type="hidden"
          name="benchatFields[subscriptions][whatsapp]"
          value="{{ benchatSubscriptions }}"
        />
        <input
          type="checkbox"
          ng-model="isChecked"
          ng-disabled="isLoading || !phone"
          ng-change="onCheckboxChange()"
        />
        <span class="message">
          {{ 'whatsapp_messenger_checkbox.subscribe' | translate }}
        </span>
      </div>
      `,
      scope: {
        phone: '<?',
        customerId: '@',
        topicStr: '@topics',
      },
      link: function ($scope) {
        const { customerId, topicStr = '' } = $scope;
        const topics = isString(topicStr)
          ? topicStr.split(',').map((s) => s.replace(/\s/g, ''))
          : [];
        const userRef = benchatService.generateUserRef(customerId);
        $scope.benchatSubscriptions = [];
        $scope.isChecked = false;
        $scope.isLoading = true;

        const getSubscriptions = () =>
          benchatService.getSubscriptions(customerId).then((result) => {
            const subscriptions = result.data?.subscriptions ?? [];
            $scope.benchatSubscriptions = subscriptions.filter(
              (subscription) =>
                subscription.isActive &&
                topics.includes(subscription.topic) &&
                subscription.platform === PLATFORM_WHATSAPP,
            );
            return $scope.benchatSubscriptions;
          });

        const finishLoading = (subscriptions) => {
          $scope.isChecked = subscriptions.length > 0;
          $scope.isLoading = false;
        };

        const upsertSubscriptions = () =>
          benchatService.patchSubscriptions(
            topics.map((topic) => ({
              userId: customerId,
              userRef,
              channelOwnerId: mainConfig.merchantData._id,
              platform: PLATFORM_WHATSAPP,
              topic,
              pageScopedId: $scope.phone,
            })),
          );

        // Original function has no error handlings
        const handleErrorSubscriptions = (res = {}) =>
          $q((resolve) => {
            const { data = [] } = res;
            // TODO: add error handling
            const filtered = data.filter(
              (item) => !Object.hasOwn(item, 'errors'),
            );
            resolve(filtered);
          });

        const deactivateSubscriptions = (subscriptions = []) =>
          $q.all(
            subscriptions.map((subscription) =>
              benchatService.deactivateSubscription(subscription._id),
            ),
          );

        const activateSubscriptions = (subscriptions = []) =>
          $q.all(
            subscriptions.map((subscription) =>
              benchatService.activateSubscription(subscription._id, {
                ...subscription,
                pageScopedId: $scope.phone,
              }),
            ),
          );

        $scope.onCheckboxChange = () => {
          $scope.isLoading = true;
          const toggleSubscriptions = $scope.isChecked
            ? activateSubscriptions
            : deactivateSubscriptions;
          if ($scope.benchatSubscriptions.length > 0) {
            toggleSubscriptions($scope.benchatSubscriptions)
              .then(getSubscriptions)
              .then(finishLoading);
          } else {
            upsertSubscriptions()
              .then(handleErrorSubscriptions)
              .then(toggleSubscriptions)
              .then(getSubscriptions)
              .then(finishLoading);
          }
        };

        getSubscriptions().then(finishLoading);
      },
    };
  },
]);
