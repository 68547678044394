export const EVENT_NAME_FOR_USER_FLOW = {
  MemberSignUpFormShow: 'MemberSignUpFormShow',
  MemberSignUpFormSubmit: 'MemberSignUpFormSubmit',
  ThirdPartySignUpClick: 'ThirdPartySignUpClick',
  MemberLoginClick: 'MemberLoginClick',
  SMSVerificationCodeSend: 'SMSVerificationCodeSend',
  SMSVerificationFormShow: 'SMSVerificationFormShow',
  SMSVerificationSendButtonClick: 'SMSVerificationSendButtonClick',
  SMSVerificationSubmit: 'SMSVerificationSubmit',
  RecaptchaVerificationStart: 'RecaptchaVerificationStart',
  RecaptchaVerificationComplete: 'RecaptchaVerificationComplete',
  MemberSignUpInfoFormShow: 'MemberSignUpInfoFormShow',
  MemberSignUpInfoSubmit: 'MemberSignUpInfoSubmit',
  MemberSignUpSucceed: 'MemberSignUpSucceed',
  MemberLoginFormShow: 'MemberLoginFormShow',
  MemberLoginSucceed: 'MemberLoginSucceed',
  UpdateMemberInfoFormShow: 'UpdateMemberInfoFormShow',
  UpdateMemberInfoSucceed: 'UpdateMemberInfoSucceed',
};

export const EVENT_CATEGORY_FOR_USER_FLOW = {
  SignUp: 'SignUp',
  MemberLogin: 'MemberLogin',
  UserVerification: 'UserVerification',
  ProfileUpdate: 'ProfileUpdate',
};

export const VALUE_FOR_USER_FLOW = {
  email: 'email',
  mobile: 'mobile',
  email_and_mobile: 'email_and_mobile',
  facebook: 'facebook',
  line: 'line',
  initial_signup: 'initial_signup',
  signup_resend: 'signup_resend',
  member_info_update: 'member_info_update',
  merchant: 'merchant',
  shopline: 'shopline',
  email_only: 'email_only',
  mobile_only: 'mobile_only',
  sms: 'sms',
  none: 'none',
};

export const PROPERTY_FOR_USER_FLOW = {
  sign_up_option: 'sign_up_option',
  third_party_sign_up_option: 'third_party_sign_up_option',
  third_party_login_option: 'third_party_login_option',
  sms_verification: 'sms_verification',
  accept_marketing: 'accept_marketing',
  purpose: 'purpose',
  verification_type: 'verification_type',
  set_up_by: 'set_up_by',
  login_option: 'login_option',
  update_info: 'update_info',
  need_verification: 'need_verification',
  pass_time: 'pass_time',
};
