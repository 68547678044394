import { delay, isEmpty } from 'lodash-es';
import {
  NOTIFICATION_TYPE_TOPIC_MAP,
  PLATFORM,
  SUBSCRIPTION_STATUS_TYPE,
} from '../../constants/benchat';

const DEFAULT_POLLING_COUNT = 5;

app.directive('facebookMessengerCheckbox', [
  'mainConfig',
  'benchatService',
  '$timeout',
  '$q',
  '$filter',
  'pnotifyService',
  function (mainConfig, benchatService, $timeout, $q, $filter, pnotifyService) {
    return {
      restrict: 'A',
      templateUrl: require('../../../../../public/themes/v1/default/views/templates.facebook-messenger-checkbox.html'),
      scope: {
        benchatConfig: '=',
        benchatChannel: '=',
        customerId: '@',
        sessionId: '@',
        notificationType: '@',
        isRevamp: '=',
      },
      link: function (scope) {
        let pollingCount = DEFAULT_POLLING_COUNT;
        scope.staticResourceHost = mainConfig.staticResourceHost;
        scope.benchatSubscriptions = [];
        scope.state = {
          isSubscriptionLoaded: false,
        };
        scope.customerIdentifier = scope.customerId || scope.sessionId;
        scope.topics = NOTIFICATION_TYPE_TOPIC_MAP[scope.notificationType];
        scope.origin = window.location.origin.replace('http://', 'https://');

        scope.userRef = benchatService.generateUserRef(
          scope.customerIdentifier,
        );
        scope.getDataRef = () => `${scope.userRef}-${scope.notificationType}`;

        const updateUserRef = function () {
          scope.userRef = benchatService.generateUserRef(
            scope.customerIdentifier,
          );
        };
        const getSubscriptions = function () {
          return benchatService
            .getSubscriptions(
              scope.customerIdentifier,
              scope.topics,
              PLATFORM.FACEBOOK,
            )
            .then(function (result) {
              const subscriptions = result.data.subscriptions;
              if (isEmpty(subscriptions)) {
                return [];
              }
              return subscriptions.filter(
                (subscription) => subscription.isActive,
              );
            });
        };

        const reloadCheckbox = function () {
          updateUserRef();
          // need to wait for all dom attributes in checkbox to be ready before re-rendering it
          $timeout(function () {
            FB.XFBML.parse();
          }, 0);
        };

        const init = function () {
          scope.state.isSubscriptionLoaded = false;
          getSubscriptions().then(function (subscription) {
            scope.state.isSubscriptionLoaded = true;
            scope.benchatSubscriptions = subscription;
            reloadCheckbox();
          });
        };

        scope.optOut = function () {
          $q.all(
            scope.benchatSubscriptions.map((subscription) =>
              benchatService.deactivateSubscription(subscription._id),
            ),
          )
            .then(function () {
              pnotifyService.notify(
                $filter('translate')('unsubscribe.success'),
                {},
              );
              init();
            })
            .catch(() => {
              pnotifyService.notify(
                $filter('translate')('unsubscribe.failure'),
                {
                  customClass: 'error',
                  icon: 'fa fa-exclamation-triangle',
                },
              );
            });
        };

        const activateSubscriptions = function (subscriptions) {
          return $q.all(
            subscriptions.map((subscription) =>
              benchatService.activateSubscription(subscription._id, {
                subscription,
              }),
            ),
          );
        };

        const getSubscriptionsResult = () =>
          delay(() => {
            benchatService
              .getSubscriptions(
                scope.customerIdentifier,
                scope.topics,
                PLATFORM.FACEBOOK,
              )
              .then((response) => {
                const subscriptionStatus = benchatService.getSubscribeStatus(
                  response.data.subscriptions,
                  scope.userRef,
                );
                if (
                  subscriptionStatus === SUBSCRIPTION_STATUS_TYPE.LOADING &&
                  pollingCount > 0
                ) {
                  pollingCount -= 1;
                  return getSubscriptionsResult();
                }

                let message = '';
                switch (subscriptionStatus) {
                  case SUBSCRIPTION_STATUS_TYPE.SUCCESS:
                    message = 'subscribe.success';
                    break;
                  case SUBSCRIPTION_STATUS_TYPE.SOCIAL_USER_DOUBLE_BIND_ERROR:
                    message = 'subscribe.failure.already_bound';
                    break;
                  case SUBSCRIPTION_STATUS_TYPE.SOCIAL_USER_BIND_ERROR:
                    message = 'subscribe.failure';
                    break;
                  default:
                    message = 'subscribe.facebook.failure';
                }
                const isError =
                  subscriptionStatus !== SUBSCRIPTION_STATUS_TYPE.SUCCESS;
                if (isError) {
                  reloadCheckbox();
                } else {
                  scope.benchatSubscriptions = response.data.subscriptions.filter(
                    (subscription) => subscription.isActive,
                  );
                }
                pollingCount = DEFAULT_POLLING_COUNT;
                scope.state.isSubscriptionLoaded = true;
                pnotifyService.notify(
                  $filter('translate')(message),
                  isError
                    ? {
                        customClass: 'error',
                        icon: 'fa fa-exclamation-triangle',
                      }
                    : {},
                );
              });
          }, 1000);

        FB.Event.subscribe('send_to_messenger', function (e) {
          if (e.event == 'opt_in') {
            if (scope.isRevamp) {
              scope.state.isSubscriptionLoaded = false;
              getSubscriptionsResult();
              return;
            }
            // before triggering facebook, create subscription in benchat first
            benchatService
              .patchSubscriptions(
                scope.topics.map((topic) => ({
                  userId: scope.customerIdentifier,
                  channelOwnerId: mainConfig.merchantData._id,
                  userRef: scope.userRef,
                  platform: PLATFORM.FACEBOOK,
                  topic,
                })),
              )
              .then((response) => activateSubscriptions(response.data))
              .then(() => {
                pnotifyService.notify(
                  $filter('translate')('subscribe.success'),
                  {},
                );
                init();
              })
              .catch(() => {
                pnotifyService.notify(
                  $filter('translate')('subscribe.facebook.failure'),
                  {
                    customClass: 'error',
                    icon: 'fa fa-exclamation-triangle',
                  },
                );
              });
          }
        });

        init();
      },
    };
  },
]);
