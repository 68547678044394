import { isEmpty, debounce } from 'lodash-es';
import { parseCheckoutObjectToOrder } from './multi_checkout/utils/common';

app.directive("orderShowPage", [
  'Analytics',
  '$filter',
  '$rootScope',
  'order',
  'action_name',
  'productService',
  'ordersService',
  'checkoutService',
  'trackerService',
  'gaService',
  'slPixelService',
  'slFeatureService',
  'hiidoTrackerService',
  'cartService',
  'mainConfig',
  'checkoutObject',
  'order_customer_id',
  'browserService',
  (
    Analytics,
    $filter,
    $rootScope,
    orderSource,
    action_name,
    productService,
    ordersService,
    checkoutService,
    trackerService,
    gaService,
    slPixelService,
    slFeatureService,
    hiidoTrackerService,
    cartService,
    mainConfig,
    checkoutObject,
    orderCustomerId,
    browserService
  ) ->
    {
      restrict: 'A'
      link: (scope, element, attrs) -> 
        order = if isEmpty(checkoutObject) then orderSource else parseCheckoutObjectToOrder(checkoutObject)

        scope.isPad = browserService.isPad()
        window.addEventListener('resize', debounce((event) ->
          scope.isPad = browserService.isPad()
          scope.$digest();
        ), 200)
        # if subtotal_items only includes redeem_gift, skip confirm tracker
        if _.every(order.subtotal_items, (item) -> item.item_points != null)
          return

        state = {
          isGaUpdated: false,
          isFbUpdated: false
        }
        isAllGift = () ->
          order.subtotal_items.length ==
            _.filter(order.subtotal_items, ((item) -> return item.item_type == 'Gift')).length

        scope.isOrderRepurchaseEnable = !!$rootScope.isUserLoggedIn &&
                                        !isAllGift() &&
                                        slFeatureService.hasFeature('order_add_to_cart')

        # payment center atm iframe posts MessageEvent to adjust dom height
        iframeDom = $('#shopline_payment_iframe_url')
        window.addEventListener('message', (event) ->
          regex = /^https:\/\/[\w-]+.myshopline.com/
          return unless event.origin.match(regex)

          iframeDom.css('height', event.data.height)
        )

        getDeliveryDollars = () ->
          if slFeatureService.hasFeature('multi_checkout')
            return order.order_delivery.dollars || 0
          order.order_delivery.total?.dollars || 0

        #GA doesnt have a payment fee, so we will add it to shipping fee
        getGAShippingFee = () ->
          deliveryDollars = getDeliveryDollars()
          orderPaymentFee = order.order_payment.payment_fee.dollars || 0
          deliveryDollars + orderPaymentFee

        sendSlpixelTracking = () ->
          orderItems = []
          if (!Array.isArray(order.subtotal_items) || !_.isObject(order))
            return
          pushItem = (item) ->
            if(!_.isObject(item))
              return
            orderItems.push {
              productID: item.object_data._id,
              type: item.item_type,
              name: $filter('translateModel')(item.object_data.title_translations),
              currency: item.item_price.currency_iso,
              price: item.item_price.dollars,
              quantity: item.quantity,
              variationID: if item.item_data.variation_data then item.item_data.variation_data.key else null
            }

          order.subtotal_items.forEach((item) ->
            pushItem(item)
          )

          slPixelService.hdTracking(
            'purchase',
            null,
            {
              cartItems: orderItems,
              total: order.total && order.total.dollars,
              currency: order.total && order.total.currency_iso,
              deliveryFee: getDeliveryDollars(),
              discount: order.order_discount && order.order_discount.dollars,
              subtotal: order.subtotal && order.subtotal.dollars,
              coupon: order.cart_attributes && order.cart_attributes.coupon_codes,
              additionalFee: order.order_payment && order.order_payment.payment_fee.dollars,
              affiliate: order.cart_attributes && order.cart_attributes.referral_code,
              tax: order.total_tax_fee && order.total_tax_fee.dollars,
              country: order.cart_attributes && order.cart_attributes.country,
              orderID: order.order_number
            }
          )

        sendSlpixelTracking()

        ordersService.getTrackingFlags(order._id)
          .then((res) ->
            trackingFlags = res.data
            if trackingFlags == null
              trackingFlags = {}
            if Analytics.configuration.enhancedEcommerce
              gaService.setUserId()
              if action_name == 'confirm' and !trackingFlags.ga_tracked
                _.each(order.subtotal_items, ((item)->
                  # Analytics.addProduct(productId, name, category, brand, variant, price, quantity, coupon, position);
                  Analytics.addProduct(
                    productService.getSku(item.object_data._id, item.object_data.sku, item.item_data.variation_data),
                    $filter('translateModel')(item.object_data.title_translations),
                    '',
                    '',
                    productService.getVariationName(item.item_data.variation_data),
                    item.item_price.dollars.toString(),
                    item.quantity,
                    '',
                    '0'
                  )
                ))

                # Analytics.trackTransaction(transactionId, affiliation, revenue, tax, shipping, coupon, list, step, option);
                Analytics.trackTransaction(
                  order.order_number,
                  'Shopline',
                  order.total.dollars.toString(),
                  '',
                  getGAShippingFee().toString(),
                  '', '', '', '')
                state.isGaUpdated = true
              Analytics.pageView()

            if action_name == 'confirm'
              hiidoTrackerService.adaptTransactionPageView()
              if !trackingFlags.fb_tracked_at
                trackerService.fbPurchase(order)
                state.isFbUpdated = true

              trackerService.track({
                type: trackerService.generalEventType.PURCHASE,
                data: {
                  customerId: orderCustomerId,
                  orderId: order._id,
                  transactionId: order.order_number,
                  items: order.subtotal_items,
                  subtotal: order.total,
                  shipping: getGAShippingFee(),
                  affiliation: 'Shopline'
                }
              })
              if !trackingFlags.auth_token_expired_at
                ordersService.afterConfirm order._id, state.isGaUpdated, state.isFbUpdated
              if slFeatureService.hasFeature('shoplytics_to_hd')
                trackProducts = order.subtotal_items.map((item) -> {
                  product_id: item.object_data._id,
                  variation_id: item.item_data.variation_data && item.item_data.variation_data.key,
                  type: item.item_type,
                  name: $filter('translateModel')(item.object_data.title_translations),
                  price: item.item_price.dollars,
                  quantity: item.quantity
                });

                trackerService.pageView({
                  data: {
                    page_type: 'order_confirmation',
                    order_id: order._id,
                    order_ids: order.order_ids,
                    currency: order.total && order.total.currency_iso,
                    products: trackProducts,
                    subtotal: order.subtotal && order.subtotal.dollars,
                    discount: order.order_discount && order.order_discount.dollars,
                    delivery_fee: getDeliveryDollars(),
                    additional_fee: order.order_payment && order.order_payment.payment_fee.dollars,
                    tax: order.total_tax_fee && order.total_tax_fee.dollars,
                    user_credit: (order.user_credit && order.user_credit.value) || 0,
                    total: order.total && order.total.dollars,
                    order_timestamp: order.order_number,
                  }
                })
              else
                trackerService.pageView()
          ).catch((data, status) ->
            #couldn't get tracking flags, dont track anything
          )

        scope.orderRepurchase = ($event) ->
          $event.stopPropagation()
          cartService.addOrderItemsToCart(order._id)
            .then ((res) -> 
              trackerService.track({
                type: trackerService.generalEventType.ADD_TO_CART,
                data: {
                  items: res.data.data.items.map((item) => ({
                    product: item.product,
                    value: item.total?.dollars || 0,
                    quantity: item.quantity,
                    variationSelected: item.variation,
                  })),
                  eventId: null,
                },
              });
            )
            .then () ->
              window.location.replace(
                mainConfig.merchantData.brand_home_url + '/orders?orderId=' + order._id,
              );
    }

])
