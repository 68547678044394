export const PLATFORM = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  WHATSAPP: 'WHATSAPP',
  FACEBOOK: 'FACEBOOK',
  LINE_LOGIN: 'LINE_LOGIN',
  LINE_MES_API: 'LINE_MES_API',
};

export const TOPIC = {
  MARKETING: 'MARKETING',
  USER_PROFILE: 'USER_PROFILE',
  ORDER_STATUS_UPDATE: 'ORDER_STATUS_UPDATE',
  DELIVERY_STATUS_UPDATE: 'DELIVERY_STATUS_UPDATE',
  PAYMENT_SLIP_UPLOAD: 'PAYMENT_SLIP_UPLOAD',
};

export const SUBSCRIPTION_STATUS_TYPE = {
  INITIAL: 'Initial',
  LOADING: 'Loading',
  SUCCESS: 'Success',
  // api error type
  USER_WITHOUT_EMAIL_ERROR: 'UserWithoutEmailError',
  USER_WITHOUT_MOBILE_ERROR: 'UserWithoutMobileError',
  UNKNOWN_ERROR: 'UnknownError',
  SOCIAL_USER_DOUBLE_BIND_ERROR: 'SocialUserDoubleBindError',
  PLATFORM_USER_ID_SIGNED_UP_BY_OTHERS_ERROR:
    'PlatformUserIdSignedUpByOthersError',
  SOCIAL_USER_BIND_ERROR: 'SocialUserBindError',
  PLATFORM_ID_DIFFERENT_ERROR: 'PlatformIdDifferentError',
};

export const NOTIFICATION_TYPE = {
  MARKETING: 'MARKETING',
  ORDER: 'ORDER',
};

export const NOTIFICATION_TYPE_TOPIC_MAP = {
  [NOTIFICATION_TYPE.MARKETING]: [TOPIC.MARKETING],
  [NOTIFICATION_TYPE.ORDER]: [
    TOPIC.ORDER_STATUS_UPDATE,
    TOPIC.DELIVERY_STATUS_UPDATE,
    TOPIC.PAYMENT_SLIP_UPLOAD,
  ],
};

export const EMAIL_MARKETING_TYPE = 'marketing.news';
