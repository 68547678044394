import { cloneDeep, isUndefined } from 'lodash-es';
import { CHECKOUT_EVENTS } from '../constants/angular-events';

app.directive('addressModule', [
  '$http',
  '$filter',
  '$timeout',
  '$rootScope',
  'addressPreferenceService',
  'addressNodesService',
  '$q',
  function (
    $http,
    $filter,
    $timeout,
    $rootScope,
    addressPreferenceService,
    addressNodesService,
    $q,
  ) {
    return {
      restrict: 'AE',
      templateUrl: require('../../../../../public/themes/shared/templates.address_module.html'),
      scope: {
        preferenceScope: '@',
        index: '@',
        deliveryTargetArea: '@',
        addr: '=',
        countries: '=',
        maxMedia: '@',
        country: '@',
        showTitle: '=',
        inputNameTemplate: '@',
        validateBeforeSubmit: '=',
        deliveryOptionId: '@',
        deliveryOptionIds: '=',
        stopUpdateFee: '=',
        regionType: '@',
        disabled: '=',
      },
      link: function (scope, element) {
        scope.levelData = {};
        scope.tmpAddr = {};
        scope.generatingStyle = true;
        scope.isAutoFillingAddress = false;
        if (
          !Object.values(addressPreferenceService.PREFERENCE_SCOPE).includes(
            scope.preferenceScope,
          )
        ) {
          scope.preferenceScope =
            addressPreferenceService.PREFERENCE_SCOPE.DISPLAY;
        }
        scope.isDisplayScope = [
          addressPreferenceService.PREFERENCE_SCOPE.DISPLAY,
          addressPreferenceService.PREFERENCE_SCOPE.DISPLAY_INLINE,
        ].includes(scope.preferenceScope);
        if (scope.isDisplayScope) {
          scope.maxMedia = 'small';
          if (_.isEmpty(scope.addr)) {
            return;
          }
        }
        // get address from rails variable
        if (typeof scope.addr === 'string') {
          scope.addr = angular.fromJson(scope.addr);
        }

        getPreference();

        scope.$watch('addr', function (newValue, oldValue) {
          if (_.isEmpty(newValue) && !_.isEmpty(oldValue)) {
            initializeAddress();
          }
        });

        function getPreference() {
          $http({
            method: 'GET',
            url: '/api/address_preferences',
            params: {
              country: scope.country,
              scope: scope.isDisplayScope
                ? addressPreferenceService.PREFERENCE_SCOPE.DISPLAY
                : scope.preferenceScope,
            },
          }).then(function (res) {
            // init address logistic code and node ids
            scope.addr.logistic_codes = scope.addr.logistic_codes || [];
            scope.addr.address_node_ids = scope.addr.address_node_ids || [];
            var preferenceRules = res.data.data;
            scope.maxPriority = preferenceRules.max_priority;
            scope.preferences = appendPreferenceField(
              preferenceRules.preferences,
            );

            if (scope.isDisplayScope) {
              initStyle();
            } else {
              scope.maxLevel = _.reduce(
                scope.preferences,
                function (maxLevel, preference) {
                  return Math.max(preference.level, maxLevel);
                },
                0,
              );

              // get level data if existed in addr
              var getLevelDataPromises;
              if (!_.isEmpty(scope.addr.address_node_ids)) {
                // if address_node_ids exist, fetch child nodes simultaneously
                getLevelDataPromises = [getLevelDataFromId()];
                if (_.isEmpty(scope.addr.address_node_ids)) {
                  initializeAddress();
                } else {
                  if (scope.country === 'MY') {
                    getLevelDataPromises.push(
                      getMYDataFromId(scope.addr.address_node_ids[0]),
                    );
                  } else {
                    _.forEach(scope.addr.address_node_ids, function (
                      node_id,
                      index,
                    ) {
                      getLevelDataPromises.push(
                        getLevelDataFromId(index + 1, node_id),
                      );
                    });
                  }
                }
                $q.all(getLevelDataPromises).then(function () {
                  if (!_.isEmpty(scope.addr)) {
                    preProcessAddr();
                  }
                  initStyle();
                });
              } else {
                // fetch sequentially to access parent node id
                getLevelDataPromises = [getNextLevel];
                _.chain(scope.preferences)
                  .sortBy(function (preference) {
                    return preference.level;
                  })
                  .reduce(function (lastItem, preference) {
                    if (
                      preference.level > 1 &&
                      scope.addr[lastItem.field_name]
                    ) {
                      getLevelDataPromises.push(function () {
                        return getNextLevel(lastItem);
                      });
                    }
                    return preference;
                  }, {});

                _.reduce(
                  getLevelDataPromises,
                  function (p, x) {
                    return p.then(x);
                  },
                  Promise.resolve(),
                ).then(function () {
                  if (!_.isEmpty(scope.addr)) {
                    preProcessAddr();
                  }
                  initStyle();
                  scope.$apply();
                });
              }
            }
          });
        }

        function preProcessAddr() {
          // translate fields in scope.addr
          _.forEach(scope.preferences, function (preference) {
            var isTwAddress2 =
              scope.country === 'TW' && preference.field_name === 'address_2';
            if (
              preference.level > 0 &&
              scope.addr[preference.field_name] &&
              !isTwAddress2
            ) {
              var supportTranslations = getSupportTranslations(
                scope.levelData[preference.level - 1],
              );
              var node = _.find(
                scope.levelData[preference.level - 1],
                function (node) {
                  return (
                    (!_.isEmpty(node._id) &&
                      node._id ===
                        scope.addr.address_node_ids[preference.level - 1]) ||
                    matchTranslation(
                      node,
                      scope.addr[preference.field_name],
                      supportTranslations,
                    )
                  );
                },
              );
              scope.addr[preference.field_name] = node
                ? node.nodeName
                : undefined;

              if (node && scope.country === 'MY') {
                var addressNodeIds = scope.addr.address_node_ids;
                if (preference.level === 1) {
                  addressNodeIds[0] = addressNodeIds[0] || node._id;
                } else if (preference.level === 3) {
                  addressNodeIds[1] = addressNodeIds[1] || node.parentNodeId;
                  addressNodeIds[2] = addressNodeIds[2] || node._id;
                }
              }
            }
          });
          // combine zip_code and region
          if (scope.country === 'TW' && scope.addr['address_2']) {
            const supportTranslations = getSupportTranslations(
              scope.levelData[1],
            );
            const fullDistrictData = _.find(scope.levelData[1], function (
              node,
            ) {
              return matchTranslation(
                node,
                scope.addr['address_2'],
                supportTranslations,
              );
            });
            scope.tmpAddr = { tw_address_2: fullDistrictData?.nodeName };
          }
        }

        function appendPreferenceField(preferences) {
          return preferences.map(function (preference) {
            var priority = preference.priority;
            if (priority > 0 && priority < scope.maxPriority) {
              var dependentField = preferences.find(function (target) {
                return target.priority === priority + 1;
              });
              if (dependentField) {
                preference.dependentField = dependentField.field_name;
              }
            }
            if (preference.rule) {
              preference.rule = new RegExp(preference.rule);
            }
            return preference;
          });
        }

        function initializeAddress() {
          scope.addr.city = undefined;
          scope.addr.address_1 = undefined;
          scope.addr.address_2 = undefined;
          scope.addr.state = undefined;
          scope.addr.postcode = undefined;
          scope.addr.district = undefined;
          scope.addr.key = undefined;
          scope.addr.logistic_codes = [];
          scope.addr.address_node_ids = [];
          scope.addr.region_code = undefined;
          scope.tmpAddr = {};
        }

        function sortPreferences(preferences, media) {
          return _.chain(preferences)
            .sortBy(function (preference) {
              return preference.layout[media].column;
            })
            .sortBy(function (preference) {
              return preference.layout[media].row;
            })
            .value();
        }

        function generateGridTemplateAreas(preferences, media) {
          var sortedPreferences = sortPreferences(preferences, media);

          return _.reduce(
            sortedPreferences,
            function (memo, preference) {
              var layout = preference.layout[media];
              if (memo.acc === 0) {
                memo.areas +=
                  "'" + (preference.field_name + ' ').repeat(layout.width);
                layout.row++;
              } else {
                memo.areas += (preference.field_name + ' ').repeat(
                  layout.width,
                );
              }
              memo.acc = (layout.width + memo.acc) % 12;
              if (memo.acc === 0) {
                memo.areas += "'";
              }
              return memo;
            },
            { areas: '', acc: 0 },
          ).areas;
        }

        function initStyle() {
          var media = 'large';
          if (window.screen.width < 768 || scope.maxMedia === 'small') {
            media = 'small';
          } else if (
            window.screen.width < 1200 ||
            scope.maxMedia === 'medium'
          ) {
            media = 'medium';
          }
          if (scope.isDisplayScope) {
            scope.preferences = sortPreferences(scope.preferences, media);
            // show postcode and region inline
            scope.displayContents = [];
            _.forEach(scope.preferences, function (preference) {
              var fieldContent;
              if (preference.field_name === 'country') {
                fieldContent = $filter('translate')(scope.country);
              } else {
                fieldContent = scope.addr[preference.field_name] || '';
              }

              if (
                preference.layout.small.width !== 12 &&
                preference.layout.small.column !== 1
              ) {
                scope.displayContents[scope.displayContents.length - 1] +=
                  ' ' + fieldContent;
              } else {
                scope.displayContents.push(fieldContent);
              }
              if (
                scope.country === 'SG' &&
                preference.field_name === 'postcode'
              ) {
                scope.displayContents[scope.displayContents.length - 1] =
                  $filter('translate')('SG') +
                  ' ' +
                  scope.displayContents[scope.displayContents.length - 1];
              }
            });
            if (
              scope.preferenceScope ===
              addressPreferenceService.PREFERENCE_SCOPE.DISPLAY_INLINE
            ) {
              scope.displayContents = scope.displayContents.join(
                scope.preferences[0]['address_separator'],
              );
            }
          } else {
            element.find('.address-module-grid').css({
              'grid-template-areas': generateGridTemplateAreas(
                scope.preferences,
                media,
              ),
            });
          }
          scope.formattedPreferences = scope.preferences;
          scope.generatingStyle = false;
          $rootScope.$broadcast(
            'address_module.template.ready',
            scope.preferenceScope,
          );
        }
        scope.onCountryChange = function () {
          // prevent from getting old value
          $timeout(function () {
            getPreference();
            initializeAddress();
          }, true);
        };

        scope.onLevelFieldChange = function (preference) {
          var newValue = scope.tmpAddr.tw_address_2;
          if (scope.country === 'TW' && preference.field_name === 'address_2') {
            if (newValue) {
              scope.addr['postcode'] = newValue.replace(/[^0-9]/g, '').trim();
              scope.addr['address_2'] = newValue.replace(/[0-9]/g, '').trim();
            } else {
              // set as disabled
              scope.addr['postcode'] = undefined;
              scope.addr['address_2'] = undefined;
            }
          }

          const node = getNode(preference) || {};
          scope.addr.address_node_ids[preference.level - 1] = node._id;
          scope.addr.region_code = node.region_code;
          if (node.code) {
            scope.addr.logistic_codes[preference.level - 1] = node.code;
          }
          if (
            preference.level < scope.maxLevel &&
            scope.addr[preference.field_name]
          ) {
            return getLevelDataFromId(preference.level, node._id);
          }
          if (
            $('#is_using_dynamic_delivery_fee').val() &&
            !scope.stopUpdateFee &&
            !scope.isAutoFillingAddress
          ) {
            calculateDeliveryFee(preference.level);
          }
        };

        function getNextLevel(preference) {
          var nextLevel = preference ? preference.level + 1 : 1;

          if (nextLevel > scope.maxLevel) {
            return;
          }
          // clear levelData of higher level
          for (var i = nextLevel; i <= scope.maxLevel; i++) {
            scope.levelData[i] = [];
          }
          return new Promise(function (resolve) {
            if (preference) {
              var node = getNode(preference);
              if (node) {
                if (scope.country === 'MY' && nextLevel === 2) {
                  getMYDataFromId(node._id).then(function () {
                    resolve();
                  });
                } else {
                  addressNodesService
                    .getLevel({
                      country: scope.country,
                      nodeId: node._id,
                      deliveryOptionId: scope.deliveryOptionId,
                      deliveryOptionIds: scope.deliveryOptionIds,
                    })
                    .then(function (res) {
                      setLevelData(preference.level, res.data);
                      $(
                        'input[name="' + scope.getInputName(preference) + '"]',
                      ).val(scope.addr[preference.field_name]);
                      resolve();
                    });
                }
              } else {
                resolve();
              }
            } else {
              addressNodesService
                .getFirstLevel(
                  scope.country,
                  scope.deliveryOptionId,
                  scope.deliveryOptionIds,
                )
                .then(function (res) {
                  setLevelData(0, res.data);
                  resolve();
                });
            }
          });
        }
        scope.onMYLevelFieldChange = function (preference) {
          var node = getNode(preference);
          if (node) {
            if (preference.level === 1) {
              scope.levelData[2] = [];
              scope.addr.logistic_codes = [node.code];
              scope.addr.address_node_ids = [node._id];
              scope.addr.city = undefined;
              scope.addr.region_code = node.region_code;
              $('input#addressModuleMYCity').val(undefined);
              return getMYDataFromId(node._id);
            } else if (preference.level === 3) {
              scope.addr.logistic_codes[1] = node.parentNodeCode;
              scope.addr.address_node_ids[1] = node.parentNodeId;
              scope.addr.logistic_codes[2] = node.code;
              scope.addr.address_node_ids[2] = node._id;
              scope.addr.city = node.parentNodeName;
              scope.addr.region_code = node.region_code;

              $('input#addressModuleMYCity').val(node.parentNodeName);
            }
          }
          if (
            $('#is_using_dynamic_delivery_fee').val() &&
            !scope.stopUpdateFee &&
            !scope.isAutoFillingAddress
          ) {
            calculateDeliveryFee(preference.level);
          }
        };

        function getMYDataFromId(nodeId) {
          return addressNodesService
            .getLevel({
              country: 'MY',
              nodeId: nodeId,
              withAllChildren: true,
              deliveryOptionId: scope.deliveryOptionId,
              deliveryOptionIds: scope.deliveryOptionIds,
            })
            .then(function (res) {
              scope.levelData[2] = res.data.MY.address_nodes[0].address_nodes.reduce(
                function (arr, node) {
                  if (node.address_nodes && node.address_nodes.length) {
                    node.address_nodes.forEach(function (childNode) {
                      childNode.nodeName = $filter('translateModel')(
                        childNode.name_translations,
                      );
                      childNode.parentNodeId = node._id;
                      childNode.parentNodeCode = node.code;
                      childNode.parentNodeName = $filter('translateModel')(
                        node.name_translations,
                      );
                    });
                    arr = arr.concat(node.address_nodes);
                  }
                  return arr;
                },
                [],
              );
            });
        }

        function getLevelDataFromId(level, nodeId) {
          // clear levelData of higher level
          for (var i = level + 1; i <= scope.maxLevel; i++) {
            scope.levelData[i] = [];
          }
          return new Promise(function (resolve) {
            if (nodeId) {
              addressNodesService
                .getLevel({
                  country: scope.country,
                  nodeId: nodeId,
                  deliveryOptionId: scope.deliveryOptionId,
                  deliveryOptionIds: scope.deliveryOptionIds,
                })
                .then(function (res) {
                  setLevelData(level, res.data);
                  resolve();
                });
            } else {
              addressNodesService
                .getFirstLevel(
                  scope.country,
                  scope.deliveryOptionId,
                  scope.deliveryOptionIds,
                )
                .then(function (res) {
                  setLevelData(0, res.data);
                  resolve();
                });
            }
          });
        }

        function matchTranslation(data, text, supportTranslations) {
          return supportTranslations.some(function (code) {
            if (!data.name_translations[code]) {
              return;
            }

            if (scope.country === 'TW') {
              return data.name_translations[code].indexOf(text) !== -1;
            } else {
              return (
                data.name_translations[code].toLowerCase() ===
                text.toLowerCase()
              );
            }
          });
        }

        function getNode(preference) {
          var supportTranslations = getSupportTranslations(
            scope.levelData[preference.level - 1],
          );
          return _.find(scope.levelData[preference.level - 1], function (node) {
            return matchTranslation(
              node,
              scope.addr[preference.field_name],
              supportTranslations,
            );
          });
        }

        function calculateDeliveryFee(level) {
          if (level === scope.maxLevel) {
            $rootScope.$broadcast(CHECKOUT_EVENTS.CART.DELIVERY.UPDATE_FEE, {
              delivery_address: {
                logistic_codes: scope.addr.logistic_codes,
              },
            });
          } else {
            $rootScope.$broadcast(
              CHECKOUT_EVENTS.CART.DELIVERY.RECALCULATE_FEE,
            );
          }
        }

        function setLevelData(level, data) {
          data = data[scope.country];
          for (var i = 0; i < level; i++) {
            data = data.address_nodes[0];
          }
          let locale;
          const countriesAllowNonEnglishAddress = ['HK', 'MO', 'SG', 'MY'];
          if (
            [
              'cross_border_711_store_pick_up',
              'cross_border_711_home_delivery',
            ].includes(scope.regionType) &&
            !countriesAllowNonEnglishAddress.includes(scope.country)
          ) {
            locale = 'en';
          }

          if (isUndefined(data?.address_nodes)) {
            return;
          }

          scope.levelData[level] = _.map(data.address_nodes, function (node) {
            return Object.assign({}, node, {
              nodeName: $filter('translateModel')(
                node.name_translations,
                locale,
              ),
            });
          });
        }

        scope.getLabel = function (preference) {
          return scope.showTitle
            ? $filter('translateModel')(preference.title_translations)
            : '';
        };

        scope.getPlaceholder = function (preference) {
          if (
            scope.showTitle &&
            _.isEmpty(preference.placeholder_translations)
          ) {
            return preference.type === 'dropdown'
              ? $filter('translate')('dropdown.hint')
              : '';
          } else if (
            scope.showTitle &&
            !_.isEmpty(preference.placeholder_translations)
          ) {
            return $filter('translateModel')(
              preference.placeholder_translations,
            );
          } else {
            return (
              $filter('translateModel')(preference.title_translations) +
              (_.isEmpty(preference.placeholder_translations)
                ? ''
                : ' : ' +
                  $filter('translateModel')(
                    preference.placeholder_translations,
                  ))
            );
          }
        };

        function getSupportTranslations(levelData) {
          if (_.isEmpty(levelData)) return [];
          return Object.keys(levelData[0].name_translations);
        }

        scope.getInputName = function (fieldName) {
          if (!fieldName) return;
          return scope.inputNameTemplate
            .replace(/\$field_name/, fieldName)
            .replace(/\$index/, scope.index);
        };

        $rootScope.$on('checkout.address_module.auto_fill', async function (
          event,
          payload,
          isAutoFillingAddress = false,
        ) {
          if (scope.isDisplayScope) {
            return;
          }
          if (!payload) {
            initializeAddress();
            return;
          }
          scope.isAutoFillingAddress = isAutoFillingAddress;
          for (const record of payload) {
            scope.addr[record.key] = cloneDeep(record.value);
            if (
              scope.country === 'TW' &&
              record?.preference?.field_name === 'address_2'
            ) {
              scope.tmpAddr.tw_address_2 = record.value;
            }
            if (record?.preference?.level) {
              if (scope.country === 'MY') {
                await scope.onMYLevelFieldChange(record.preference);
              } else {
                await scope.onLevelFieldChange(record.preference);
              }
            }
          }
          scope.isAutoFillingAddress = false;
          preProcessAddr();
          scope.$apply();
        });
      },
    };
  },
]);
