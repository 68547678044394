app.controller('productQuickCartController', [
  '$scope',
  '$timeout',
  '$filter',
  '$uibModalInstance',
  'productService',
  'trackerService',
  'backInStockService',
  'cartService',
  'mainConfig',
  'modalTypes',
  '$rootScope',
  '$window',
  'slFeatureService',
  'buyNowService',
  'imageService',
  'fbService',
  'fastCheckoutService',
  'productSetService',
  'productPreorderService',
  'productStockService',
  function (
    $scope,
    $timeout,
    $filter,
    $uibModalInstance,
    productService,
    trackerService,
    backInStockService,
    cartService,
    mainConfig,
    modalTypes,
    $rootScope,
    $window,
    slFeatureService,
    buyNowService,
    imageService,
    fbService,
    fastCheckoutService,
    productSetService,
    productPreorderService,
    productStockService,
  ) {
    //variables shared by directive product.variation-dropdown
    //TODO: Refactor
    $scope.addItemQuantity = 1;
    $scope.variationSelected = $scope.product.variations.find(function (
      variation,
    ) {
      return variation.key === $scope.variantId;
    });

    $scope.state = {
      loading: false,
      checkoutReady: false,
    };

    $scope.isRegularMobileLayout = ![
      'kingsman_v2',
      'dusk',
      'doris_bien',
      'varm',
    ].includes(mainConfig.merchantData.current_theme_key);

    $scope.shouldShowMessageMeBtn = function () {
      return (
        (!$scope.state.checkoutReady ||
          !$scope.hasStock ||
          $scope.product.hide_price) &&
        !backInStockService.shouldShowBtn(
          $scope.product,
          $scope.variationSelected,
        )
      );
    };

    $timeout(function () {
      return $('.title-container.ellipsis').each(function () {
        var desiredHeight;
        desiredHeight = $(this).attr('data-max-height')
          ? parseInt($(this).attr('data-max-height'), 10)
          : 45;
        return $(this).dotdotdot({
          wrap: 'letter',
          height: desiredHeight,
          ellipsis: '...',
        });
      });
    }, 100);

    var afterProductLoaded = function () {
      $scope.state.checkoutReady = productService.validateCheckoutReady(
        $scope.product,
        $scope.variationSelected,
        cartService.getItemPrice({
          product: $scope.product,
          variation: $scope.variationSelected,
        }),
      );
      $scope.product.digest_variations = [];
      $scope.hasStock = productStockService.checkHasStock(
        $scope.product,
        $scope.variationSelected,
      );

      if (
        !$scope.product.variations ||
        $scope.product.variations.length < 1 ||
        $scope.product.hide_price
      ) {
        trackerService.gaSendDetails($scope.product, false, $scope.products);
        $scope.products = null; //clear the related products as they are only for impressions
        var price = cartService.getItemPrice({ product: $scope.product });
        var dollars = _.isObject(price) ? price.dollars : 0;
        trackerService.fbSendViewContent($scope.product, dollars);
        trackerService.track({
          type: trackerService.generalEventType.VIEW_CONTENT,
          data: {
            product: $scope.product,
            value: dollars,
          },
        });
      }

      var media = $scope.product.media[0];
      if (media) {
        $scope.productImageUrl =
          media.default_image_url || imageService.getMediaImageUrl(media);
        $scope.productImageAlt = media.alt_translations;
      }
    };

    function getVariationId() {
      if ($scope.product.variations.length === 0) {
        return '';
      }
      return $scope.variationSelected
        ? $scope.variationSelected.key
        : $scope.product.variations[0].key;
    }
    $scope.showOutOfStockHint = function () {
      const productStockInfo = productStockService.getProductStockInfo(
        $scope.product,
        $scope.variationSelected,
      );
      return productStockService.isOutOfStock(productStockInfo);
    };
    function initStockStatus() {
      const variation_id = $scope.variationSelected
        ? $scope.variationSelected.key
        : '';
      productStockService
        .checkStockPromise({
          product: $scope.product,
          variationId: variation_id,
          selectedProductSetData: $scope.selectedProductSetData,
        })
        .then(function ({ data }) {
          const result = cartService.checkStockResult(0, data);
          $scope.quantityOfStock = result.quantityOfStock;

          if ($scope.variationSelected) {
            $scope.variationSelected.cart_quantity = data.cart_quantity;
            $scope.variationSelected.quantity = data.quantity;
            $scope.variationSelected.preorder_limit = data.preorder_limit;
            return;
          }
          $scope.product.cart_quantity = data.cart_quantity;
          $scope.product.quantity = data.quantity;
          $scope.product.preorder_limit = data.preorder_limit;
        });
    }

    function updateHasStock() {
      $scope.hasStock = productStockService.checkHasStock(
        $scope.product,
        $scope.variationSelected,
      );
    }

    // watch after product-variation-dropdown  loaded
    $timeout(function () {
      // Skip init stock status when hide price toggle on.
      if ($scope.product.hide_price) {
        // Select in stock variant because scope.variantId will be empty when hide_price toggle on.
        $scope.variationSelected = productStockService.getHasStockVariant(
          $scope.product,
        );
        updateHasStock();
        return;
      }
      initStockStatus();
      $scope.$watch('variationSelected', function checkStockQuantity() {
        updateHasStock();
        if (
          !$scope.product.unlimited_quantity &&
          $scope.product.type !== 'product_set'
        ) {
          const variation_id = getVariationId();
          $scope.orderQuantityStatus =
            cartService.QUANTITY_VERIFY_STATUS.IS_AVAILABLE;

          productService
            .checkStock($scope.product._id, variation_id)
            .then(function ({ data }) {
              // check if variation id changed after request
              if (!variation_id || variation_id !== getVariationId()) {
                return;
              }

              if ($scope.isProductSetBasket) {
                const quantityInBasket =
                  $scope.basketCartItems.find((item) => {
                    return (
                      item._id === $scope.product._id &&
                      item.variationSelected &&
                      item.variationSelected.key === variation_id
                    );
                  })?.quantity || 0;
                data.cart_quantity += quantityInBasket;
              }
              const result = cartService.checkStockResult(0, data);
              $scope.quantityOfStock = result.quantityOfStock;
              $scope.orderQuantityStatus = result.orderQuantityStatus;
              if ($scope.variationSelected) {
                $scope.variationSelected.cart_quantity = data.cart_quantity;
                $scope.variationSelected.quantity = data.quantity;
                $scope.variationSelected.preorder_limit = data.preorder_limit;
              }
            });
        }

        if ($scope.isProductSetBasket) {
          checkExceedMinQuantity();
        }
      });
    });

    $scope.isDefined = angular.isDefined;

    if (!_.isEmpty($scope.product)) {
      afterProductLoaded();
    }

    $scope.showMessageForm = function () {
      $uibModalInstance.dismiss('canceled');
      $rootScope.showMessageForm();
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('canceled');
      $rootScope.$emit('modal.close', { modalType: $scope.modalType });
      fbService.hideFacebookCustomerChat();
    }; // end cancel

    $scope.incQuantity = function () {
      if (parseInt($scope.addItemQuantity, 10) < 99999) {
        $scope.addItemQuantity = parseInt($scope.addItemQuantity, 10) + 1;
      }
      if ($scope.isProductSetBasket) {
        checkExceedMinQuantity();
      }
    };

    $scope.decQuantity = function () {
      if (parseInt($scope.addItemQuantity, 10) > 1) {
        $scope.addItemQuantity = parseInt($scope.addItemQuantity, 10) - 1;
      }
      if ($scope.isProductSetBasket) {
        checkExceedMinQuantity();
      }
    };

    $scope.getAddToCartButtonText = function (isBuyNowButtonEnabled) {
      const productStockInfo = productStockService.getProductStockInfo(
        $scope.product,
        $scope.variationSelected,
      );
      return productPreorderService.getAddToCartButtonText({
        productStockInfo,
        addItemQuantity: $scope.addItemQuantity,
        isBuyNowButtonEnabled,
      });
    };

    $scope.addItemToCart = function (event, config) {
      var vm = this;

      var regex = /^[1-9][0-9]{0,4}$/;
      $scope.invalidQuantity = !regex.test($scope.addItemQuantity);

      if ($scope.invalidQuantity) {
        $rootScope.$broadcast('cartItemUpdateFailed');
        return;
      }

      if (!config.isSPBFastCheckout) $scope.state.loading = true;

      const variation_id = $scope.variationSelected
        ? $scope.variationSelected.key
        : '';

      // Turn inputbox value (ng-model=addItemQuantity) String to Number.
      vm.addItemQuantity = parseInt($scope.addItemQuantity, 10);

      const isFastCheckoutCart =
        (config.isBuyNow && fastCheckoutService.isEnableEc) ||
        (config.isSPBFastCheckout && fastCheckoutService.isSpbEnabled);

      productStockService
        .checkStockPromise({
          product: $scope.product,
          variationId: variation_id,
          selectedProductSetData: $scope.selectedProductSetData,
          isFastCheckoutCart,
        })
        .then(function ({ data }) {
          $scope.product.max_order_quantity = data.max_order_quantity;
          const result = cartService.checkStockResult(
            vm.addItemQuantity,
            data,
            isFastCheckoutCart,
          );
          const isOutOfStockOrderable = productService.isOutOfStockOrderable(
            $scope.product,
          );
          $scope.reachedPurchaseLimit = result.reachedPurchaseLimit;
          $scope.notEnoughStockQty = result.notEnoughStockQty;
          $scope.quantityOfStock = result.quantityOfStock;
          $scope.orderQuantityStatus = result.orderQuantityStatus;

          if ($scope.variationSelected) {
            $scope.variationSelected.cart_quantity = data.cart_quantity;
          } else {
            $scope.product.cart_quantity = data.cart_quantity;
          }

          // should not add item to cart
          if (
            (result.notEnoughStockQty >= 0 && !isOutOfStockOrderable) ||
            $scope.reachedPurchaseLimit
          ) {
            $rootScope.$broadcast('cartItemUpdateFailed');
            $scope.state.loading = false;
            return;
          }

          var cartItemData = {
            quantity: vm.addItemQuantity,
            variation: $scope.variationSelected,
            blacklisted_payment_option_ids:
              $scope.product.blacklisted_payment_option_ids,
            blacklisted_delivery_option_ids:
              $scope.product.blacklisted_delivery_option_ids,
          };

          if (productSetService.isProductSetEnabled($scope.product)) {
            cartItemData.type = 'product_set';
            cartItemData.productSetData = productSetService.generateProductSetParams(
              $scope.selectedProductSetData,
            );
          }

          if ($scope.product.subscription_enabled) {
            cartItemData['type'] = 'subscription_product';
          }

          if (config.isBuyNow) {
            cartItemData.isFastCheckoutCart = fastCheckoutService.isEnableEc;
          }

          if (config.isSPBFastCheckout) {
            cartItemData.isFastCheckoutCart = fastCheckoutService.isSpbEnabled;
          }

          const value =
            vm.addItemQuantity *
            cartService.getItemPrice({
              product: $scope.product,
              variation: $scope.variationSelected,
            }).dollars;
          cartItemData.value = value;

          cartService
            .addItem(
              $scope.product._id,
              cartItemData,
              $scope.recStrategy ? { rec_strategy: $scope.recStrategy } : {},
              config.isPromotionCart
                ? { skip_calculate_order: false }
                : { skip_calculate_order: true },
            )
            .then(function (data) {
              if (slFeatureService.hasFeature('data_layer_info')) {
                $rootScope.$emit(
                  'add.item.to.cart',
                  cartService.getGaItemData({
                    product: $scope.product,
                    variant: $scope.variationSelected,
                  }),
                );
              }

              if ($scope.variationSelected) {
                $scope.variationSelected.cart_quantity =
                  $scope.variationSelected.cart_quantity +
                  $scope.addItemQuantity;
              } else {
                $scope.product.cart_quantity =
                  $scope.product.cart_quantity + $scope.addItemQuantity;
              }

              trackerService.track({
                type: trackerService.generalEventType.ADD_TO_CART,
                data: {
                  items: [
                    {
                      product: $scope.product,
                      value,
                      quantity: $scope.addItemQuantity,
                      variationSelected: $scope.variationSelected,
                    },
                  ],
                  eventId: data.event_id,
                },
              });
              $scope.notEnoughStock = -1; //means that there is enough stock
              if (config.isBuyNow) {
                $rootScope.$broadcast('clicked_buy_now_button');
              } else if (
                !config.isPromotionCart &&
                (fastCheckoutService.shouldShowSpbModal() ||
                  buyNowService.shouldShowBuyNowModal())
              ) {
                buyNowService.openModal();
              } else if (config.isSPBFastCheckout) {
                // do nothing, handle redirect in directive.paypal.fast-checkout-button.js
              } else {
                if (!config || (config && !config.isPromotionCart)) {
                  jQuery.sidr('open', 'cart-panel');
                }
                $scope.cancel();
                $scope.state.loading = false;
              }
            })
            .catch((error) => {
              if (error.type === 'overLimit') {
                $uibModalInstance.dismiss('overLimit');
              }
            });
        }); // End of productService.checkStock
    };

    $scope.$on('product.gallery.selectMedia', function (e, selectedMedia) {
      $scope.productImageUrl =
        selectedMedia.default_image_url ||
        imageService.getMediaImageUrl(selectedMedia);
      $scope.productImageAlt = selectedMedia.alt_translations;
    });

    $scope.$on('biancoModalFastCheckout', function () {
      $scope.addItemToCart(undefined, { isSPBFastCheckout: true });
    });

    $scope.width = $window.innerWidth;
    angular.element($window).bind('resize', function () {
      $scope.width = $window.innerWidth;
      $scope.$digest();
    });

    $scope.isTabletWidth = function () {
      // this is strictly for the specific case where we want a button that shouldnt exist on mobile OR desktop
      return $scope.width == 768;
    };

    $scope.$on('quick_cart.product.loaded', function (event, product) {
      if (productSetService.isProductSetEnabled(product)) {
        productService.getById($scope.product._id).then(function (res) {
          $scope.product = res.data.data;
          $scope.selectedProductSetData = [];
        });
      } else {
        $scope.product = product;
      }
      afterProductLoaded();
    });

    if (productSetService.isProductSetEnabled($scope.product)) {
      $scope.$on('product_set.child_product.changed', function (
        event,
        payload,
      ) {
        $scope.hasStock = payload.hasStock;
        $scope.selectedProductSetData = payload.selectedProductSetData;
        if (mainConfig.merchantData.enabled_stock_reminder) {
          productService
            .checkProductSetStock({
              id: $scope.product._id,
              productSetData: productSetService.generateProductSetParams(
                $scope.selectedProductSetData,
              ),
            })
            .then(function (res) {
              var data = res.data;
              var result = cartService.checkStockResult(0, data);
              $scope.quantityOfStock = result.quantityOfStock;
              $scope.orderQuantityStatus = result.orderQuantityStatus;
            });
        }
      });
    }

    $scope.productService = productService;

    $scope.lowerProductPrice = function (product) {
      if (
        product.member_price &&
        product.member_price.cents > 0 &&
        mainConfig.currentUser &&
        slFeatureService.hasFeature('member_price')
      ) {
        return product.member_price.label;
      } else if (product.price_sale && product.price_sale.cents > 0) {
        return product.price_sale.label;
      }
    };

    $scope.lowerProductPriceMembersTag = function (product) {
      if (!product) {
        return '';
      }
      if (
        !productService.hasFlashPrice(product) &&
        product.member_price &&
        product.member_price.cents > 0 &&
        mainConfig.currentUser &&
        slFeatureService.hasFeature('member_price')
      ) {
        var memberOnlyTag = $filter('translate')('product.member_only');
        var memberTierPrice =
          mainConfig.currentUser && product.product_price_tiers
            ? _.find(product.product_price_tiers, function (tier) {
                return (
                  tier.membership_tier_id ===
                  mainConfig.currentUser.membership_tier_id
                );
              })
            : null;
        if (
          slFeatureService.hasFeature('tier_member_price') &&
          !_.isEmpty((memberTierPrice || {}).member_price) &&
          memberTierPrice.member_price.cents > 0
        ) {
          memberOnlyTag = $filter('translate')('product.tier_member_only', {
            tierName: $filter('translateModel')(
              mainConfig.currentUser.membership_tier.name_translations,
            ),
          });
        }
        return memberOnlyTag;
      }

      return '';
    };

    $scope.showVariationPrice = function (variation) {
      // not call showVariationPrice until variationSelected binded
      if (variation) return productService.showVariationPrice(variation);
    };

    function scrollToError() {
      if (!$scope.isProductSetBasket) return;
      $('.product-set-quick-cart-modal .modal-content').animate(
        {
          scrollTop: $('.product-set-quick-cart-modal .txt-notify').offset()
            .top,
        },
        300,
      );
    }

    $scope.getProductInBasket = function () {
      return $scope.basketCartItems.filter(
        (item) => item._id === $scope.product._id,
      );
    };

    $scope.getProductQuantityInBasket = function () {
      return $scope.getProductInBasket().reduce((accu, curr) => {
        return accu + curr.quantity;
      }, 0);
    };

    $scope.getChildVariationName = productSetService.getChildVariationName;

    $scope.removeItem = function (targetItem) {
      const index = $scope.basketCartItems.findIndex((item) => {
        return (
          item._id === targetItem._id &&
          item.variationSelected.key === targetItem.variationSelected.key
        );
      });
      $scope.basketCartItems.splice(index, 1);
    };

    $scope.getMinQuantityDiff = function () {
      return (
        ($scope.product.bundle_set?.min_quantity || 1) -
        $scope.getProductQuantityInBasket()
      );
    };

    $scope.getQuantityLabelText = () => {
      return $scope.getMinQuantityDiff() === 0
        ? $filter('translate')('product_set.quantity_label.qualified', {
            quantity: $scope.getProductQuantityInBasket(),
          })
        : $filter('translate')('product_set.quantity_label.unqualified', {
            quantity: $scope.getMinQuantityDiff(),
          });
    };

    function checkExceedMinQuantity() {
      const productQuantityInBasket = $scope.getProductQuantityInBasket();
      $scope.exceedRequiredQuantity =
        productQuantityInBasket + $scope.addItemQuantity >
        ($scope.product.bundle_set?.min_quantity || 1);
    }

    function getQuantitiesInBasket() {
      return (
        $scope.basketCartItems.find((item) => {
          return (
            item._id === $scope.product._id &&
            item.variationSelected &&
            item.variationSelected.key === $scope.variationSelected?.key
          );
        })?.quantity || 0
      );
    }

    $scope.addItemToBasket = function () {
      if (!$scope.isProductSetBasket) return;

      const regex = /^[1-9][0-9]{0,4}$/;
      $scope.invalidQuantity = !regex.test($scope.addItemQuantity);
      if ($scope.invalidQuantity) return;

      // Turn inputbox value (ng-model=addItemQuantity) String to Number.
      $scope.addItemQuantity = parseInt($scope.addItemQuantity, 10);

      // check if exceed required quantity
      checkExceedMinQuantity();
      if ($scope.exceedRequiredQuantity) {
        scrollToError();
        return;
      }

      $scope.state.loading = true;
      productService
        .checkStock($scope.product._id, $scope.variationSelected?.key, null)
        .then(({ data }) => {
          const quantitiesInBasket = getQuantitiesInBasket();
          data.cart_quantity += quantitiesInBasket;
          data.max_order_quantity = -1; // overwrite for child product
          const result = cartService.checkStockResult(
            $scope.addItemQuantity,
            data,
          );

          $scope.reachedPurchaseLimit = result.reachedPurchaseLimit;
          $scope.notEnoughStockQty = result.notEnoughStockQty;
          $scope.quantityOfStock = result.quantityOfStock;
          $scope.orderQuantityStatus = result.orderQuantityStatus;

          if ($scope.variationSelected) {
            $scope.variationSelected.cart_quantity = data.cart_quantity;
          } else {
            $scope.product.cart_quantity = data.cart_quantity;
          }

          // should not add item to cart
          if (
            (result.notEnoughStockQty >= 0 &&
              !productService.isOutOfStockOrderable($scope.product)) ||
            $scope.reachedPurchaseLimit
          ) {
            scrollToError();
            return;
          }

          $scope.$emit('product-set.add-child-variation', {
            product: $scope.product,
            variationSelected: $scope.variationSelected,
            quantity: $scope.addItemQuantity,
          });

          const productQuantityInBasket = $scope.getProductQuantityInBasket();
          if (
            productQuantityInBasket ===
            ($scope.product.bundle_set?.min_quantity || 1)
          ) {
            $uibModalInstance.close();
          }
        })
        .finally(() => {
          $scope.state.loading = false;
        });
    };

    $scope.showStockQuantityHint = function () {
      return productStockService.showStockQuantityHint({
        product: $scope.product,
        variationSelected: $scope.variationSelected,
        checkoutReady: $scope.state.checkoutReady,
        quantityOfStock: $scope.quantityOfStock,
      });
    };
  },
]);
