app.config([
  '$translateProvider',
  function ($translateProvider) {
    $translateProvider.translations('ja', {
      'languages.en': 'English',
      'languages.jp': '日本語',

      'form.action.delete': '削除',
      'recaptcha.language': 'jp',

      'paypal.language': 'ja_JP',

      'form.validation.required': '{{field_name}}は必須です ',
      'form.validation.maxlength': '{{field_name}}が長すぎます ',
      'form.validation.minlength': '{{field_name}}が短すぎます ',
      'form.validation.invalid': '{{field_name}}は無効です ',
      'form.validation.pattern': '{{field_name}}は無効です ',
      'form.validation.pattern.recipient-name':
        'スペースや記号、5文字以上は許可されません。',
      'form.validation.pattern.recipient-phone.TW':
        '{{field_name}}は10桁の数字を入力する必要があります ',
      'form.validation.email': '{{field_name}}は無効です ',
      'form.validation.unique':
        '{{field_name}}がもう使用されました。再度に選択してください。',
      'form.validation.confirmation': 'パスワードが一致していません。',
      'form.validation.policy':
        '続行するには、利用規約に同意する必要があります。 ',
      'form.validation.delivery_option.outlying':
        '選択した配送タイプは、台湾の外来島ではご利用いただけません。配送先住所を変更するか、別の配送方法を選択してください。 ',
      'form.validation.delivery_option.local':
        '選択した配送タイプは、台湾の外来島ではご利用いただけません。配送先住所を変更するか、別の配送方法を選択してください。 ',
      'form.validation.delivery_option.country':
        '選択した配送タイプは、台湾の島ではご利用いただけません。配送先住所を変更するか、別の配送方法を選択してください。 ',
      'form.validation.delivery_option.region':
        '選択した配送タイプは、選択した地方ではご利用いただけません。配送先住所を変更するか、別の配送方法を選択してください。 ',
      'form.validation.birthday.invalid': 'Birthday is invalid',
      'form.validation.birthday.invalid.minimum_age_limit':
        'Birthday is invalid, should over {{minimumAgeLimit}} years old.',
      'form.validation.card.bin.error':
        'Current payment method is not applicable to your credit card. Please contact shop owner for details.',
      'form.validation.card.bin.error.unknow_scheme':
        'We cannot identify your credit card type. Please email shop owner for details.',

      'dropdown.hint': '(～を)選択してください',

      'session.signup.title': 'サインアップす',
      'session.terms.facebook': 'Facebookのアカウントでサインアップす',
      'session.signup.facebook': 'Facebookログイ',
      'sessions.or': '又',
      'session.terms':
        '持続することにより、利用規約に同意し たも の と 見な します',
      'session.signin.submit': 'サインイ',
      'session.forgot_password': 'パスワードをお忘れです',
      'session.signin.title': 'サインイ',
      'session.signin.facebook': 'Facebookログイ',
      'session.mobile_signup.check_mobile.title':
        'お使いの携帯電話番号が次のように正確かご確認ください。SMSはこの携帯電話番号に送信しております ',
      'session.mobile_signup.check_mobile.your_number': 'お使いの携帯電話番号',
      'session.mobile_signup.check_mobile.your_number.hint':
        '台湾の携帯電話番号（09で始まる）を入力してください',
      'session.mobile_signup.check_mobile.send_code':
        'チェックコードを送信してください',
      'session.mobile_signup.check_mobile.go_back.1':
        '携帯番号が間違っている場合、修正するように戻ることができます ',
      'session.mobile_signup.check_mobile.go_back.2': '戻る',
      'session.mobile_signup.check_mobile.go_back.3':
        '携帯番号が間違っている場合、修正するよう',
      'session.mobile_signup.input_code.hint':
        '受け取ったチェックコードを入力してください ',
      'session.mobile_signup.input_code.submit': '続く',
      'session.mobile_signup.input_code.resend.countdown':
        '携帯電話にチェックコードSMSを再送信してください（{{resendCountdown}}秒）',
      'session.mobile_signup.input_code.resend':
        '携帯電話にチェックコードSMSを再送信してください',
      'session.forget_passowrd.check_phone.error':
        '入力した電話番号が間違っています。再度、ご確認してください。',
      'session.mobile_center.check_phone.error':
        '携帯電話番号はすでに使用されました。',

      'user.quick_signup.title': 'メンバーとしてすぐに入会しよう！',
      'user.quick_signup.title.content': '限定の売り出しをお見逃しなく！ ',
      'user.quick_signup.promotion': '私は最新のアップデートと割引を受信したい',
      'user.quick_signup.policy':
        '私はお店の利用規約とプライバシーポリシーに同意しま',
      'user.quick_signup.policy.url_content':
        'ショップの利用規約とプライバシーポリ',
      'user.quick_signup.submit': '今すぐ参加しよう',
      'user.quick_signup.error.format': 'フォーマットエラー ',
      'user.quick_signup.error.confirmed':
        '入力した携帯電話番号はすでに使用されました。',
      'user.quick_signup.error.confirmed.url': '今すぐログインしよう',
      'user.quick_signup.validate.title': 'サインアップを続ける',
      'user.quick_signup.validate.success':
        'メンバーとしてご参加いただきありがとうございます！<br/>サインアップの手続きを持続する、<br/>又は、買い物を始めるのができます。',
      'user.quick_signup.validate.success.shop': '買い物を始めるのができ',
      'user.quick_signup.validate.finish_hint':
        'すぐに弊店のクレジットを確認して適用するように、サインアップの手続きを完了してください！',
      'user.quick_signup.validate.phone': '認証する ',
      'user.quick_signup.validate.send.sms': '認証コードを送信する ',
      'user.quick_signup.validate.wrong.number':
        '携帯電話番号が正確ではない場合、～をしてください。',
      'user.quick_signup.validate.previous': '戻る',
      'user.quick_signup.validate.modify': 'そして更新する',
      'user.quick_signup.validate.hint.password': '少なくとも8文字',
      'user.quick_signup.validate.hint.send':
        '認証コードが送信されます。{{count}}秒後に新しい認証コードを再送信することができます。 ',
      'user.quick_signup.validate.hint.resend':
        'チェックコードを受信しないですか？再送信するようにここにクリックしてください。',
      'user.quick_signup.validate.submit': 'サインアップの手続きを完了する',
      'user.quick_signup.validate.placeholder.sms': '認証コードを入力する',
      'user.quick_signup.validate.placeholder.name': '氏名',
      'user.quick_signup.validate.placeholder.password': 'パスワードを入力する',
      'user.sign_up.email_verification.dialog.title':
        '確認メールは{{mail}}に送信されます。',
      'user.sign_up.email_verification.dialog.description':
        '今すぐメールをご確認してください。新しいメールアドレスが更新されます。まだ受信しない場合、再度お使いのメールを入力して、保存してください。 ',
      'user.sign_up.email_verification.dialog.confirm': '確認する',
      'user.delivery_data.recipient_phone': '受信者の電話番号',

      'product.out_of_stock': '在庫切',
      'product.addon_products.label': 'アドオンアイテム ',
      'product.bundled_products.label': '抱き合わせ販売',
      'product.bundle_group_products.label': 'A + B',
      'product.buyandget.label': 'Buy X Get Y',
      'product.addon_products.errors.main_product_out_of_stock':
        '主要商品に十分な在庫がない',
      'product.addon_products.errors.reached_max_purchase_quantity':
        '注文ごとに{{ message }}を制限する。',
      'product.addon_products.errors.addon_product_out_of_stock':
        'アドオンアイテムに十分な在庫がない',
      'product.addon_products.errors.larger_addon_product':
        'アドオンアイテムの数量は主要商品数量を超えることはできません',
      'product.addon_products.errors.out_of_stock':
        'アドオン{{title}}.に十分な在庫がない',
      'product.addon_products.tips.limit_exceed':
        '数量を調整すると、アドオンアイテムの数量は主要商品数量を超えることはできません。',
      'product.addon_products.add_to_cart.hint':
        'カートにアイテムを追加しました',
      'product.add_to_cart': 'カートに入れる',
      'product.add_to_cart_fail': 'Your cart is full!',
      'product.buy_now': '今すぐ購入',
      'product.buy_now.preorder.hint': 'Now taking pre-orders',
      'product.buy_together': '一緒に購入',
      'product.preorder_limit.hint':
        'The purchase quantity includes both in-stock and preordered items.',
      'product.coming_soon': '近日販売予定',
      'product.available_time_over': '販売期間が終了しました',
      'product.store_stock.check_stock': 'Check Stock Availability at Stores',
      'product.set.open_variation': 'View product details',

      'product.available_time_info.start.1': '',
      'product.available_time_info.start.2': ' から商品を入手可能 ',
      'product.available_time_info.end.1': '',
      'product.available_time_info.end.2': ' 商品に利用できなくなります',

      'member.center.load.more': '他のアイテム',
      'member.center.load.more.error':
        'データを読み込めません。再度お試しください。 ',

      'wishlist.signin_register': '今すぐサインイン/登録',
      'wishlist.messages.remove_error':
        'お気に入りリストアイテムを削除できません。',
      'back_in_stock.subscibe.success.message':
        '商品を再入荷したら、お知らせをお送りします。',

      orders: '注文',
      order: '注文',
      'orders.fields.billing_address': '請求先住所',
      'orders.fields.order_number': '発注番号',
      'orders.fields.order_date': '日付',
      'orders.fields.order_status': '状態',
      'orders.fields.order_remarks': '注文の備考',
      'orders.fields.customer_name': '顧客名',
      'orders.fields.product_subscription_period': '{{period}}番目',
      'orders.fields.status.temp': '準備中',
      'orders.fields.status.pending': '準備中',
      'orders.fields.status.confirmed': '確認済み',
      'orders.fields.status.shipped': '出荷済み',
      'orders.fields.status.completed': '完了',
      'orders.fields.status.received': '受け取った',
      'orders.fields.status.cancelled': 'キャンセルされた',
      'orders.fields.status.returning': '返品中',
      'orders.fields.status.returned': '返品された',
      'orders.fields.preorder_item': '先行予約するアイテム',
      'orders.fields.customer': '顧客',
      'orders.fields.customer.phone': '顧客の電話番号',
      'orders.fields.billto': '請求書送付先',
      'orders.fields.payment_type': 'お支払い方法',
      'orders.fields.payment_instructions': '支払い手順',

      'orders.fields.payment_method.validation.limit_maximum.cvs':
        '失敗したトランザクション：CVS支払いの最大注文額はNT $ 20000です',
      'orders.fields.payment_method.validation.limit_lowest.cvs':
        '失敗したトランザクション：CVS支払いの最小注文額はNT $ 30です',
      'orders.fields.payment_method.validation.limit_maximum.barcode':
        '失敗したトランザクション：バーコード支払いの最大注文額はNT $ 20000です',
      'orders.fields.payment_method.validation.limit_lowest.barcode':
        '失敗したトランザクション：バーコード支払いの最小注文額はNT $ 30です',

      'orders.fields.payment_method.validation.limit_exceeded.allpay_cvs':
        'CVSを使用するトランザクションあたりの上限はNTD20,000です',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_barcode':
        'バーコードを使用するトランザクションあたりの上限はNTD20,000です',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_webatm':
        'WebATMを使用するトランザクションあたりの上限はNTD30,000です',
      'orders.fields.delivery_method': '配送方法',
      'orders.fields.delivery_description': '説明',
      'orders.fields.delivery_fee': '配送料',
      'orders.fields.delivery_address': '配送先住所',
      'orders.fields.delivery_address.remarks': '配達備考',
      'orders.fields.accept_terms.validation.required':
        '続行するには、利用規約に同意する必要があります',
      'orders.fields.options.blacklist.erro':
        '選択した商品には、異なる配送方法や支払い方法があります。他の有効な方法を選択するか、有効な方法に応じて注文してください',
      'orders.show.message.thankyou.title': 'ご発注頂いて有難うございました',
      'orders.show.message.thankyou.description': ' ',
      'orders.show.thankyou.continue': '今すぐ買い物を続ける！',

      'orders.show.message.shipped.title': '注文されたアイテムが出荷されました',
      'orders.show.message.shipped.description': ' ',

      'orders.payment_slip.order_info.amount': '注文金額',
      'orders.payment_slip.order_info.instructions': '支払指図書',
      'orders.payment_slip.order_info.read_more': 'ビューのフル命令',
      'orders.payment_slip.upload_form.title':
        '転送が完了した後、支払転送の詳細をアップロードしてください',
      'orders.payment_slip.upload_form.hint':
        'ご入金を確認後、私たちはできるだけ早く出荷を手配します！',
      'orders.payment_slip.upload_form.notification':
        '最新の注文通知を取得するには、購読してください',
      'orders.payment_slip.upload_form.image_input.title':
        'アップロード転送の詳細',
      'orders.payment_slip.upload_form.image_input.description':
        '画像が鮮明で、トランザクション数、転送時間と量の最後の5桁が含まれていることをご確認ください。',
      'orders.payment_slip.upload_form.image_input.change':
        'クリックして画像を変更',
      'orders.payment_slip.upload_form.image_input.error':
        '画像サイズは10MB未満である必要があります',
      'orders.payment_slip.upload_form.paid_time': '支払時間を選択します。',
      'orders.payment_slip.upload_form.message': 'お支払い備考欄にご記入',
      'orders.payment_slip.upload_form.message.error':
        '{{ n }} 文字を超えることはできません',
      'orders.payment_slip.uploaded.alert':
        '我々はできるだけ早く確認する、アップロード振り込みの詳細情報をありがとう！',
      'orders.payment_slip.uploaded.notification':
        '最新の注文情報を取得するために購読',
      'orders.payment_slip.uploaded.title':
        'ここでは、以下のお振込みの詳細です',
      'orders.payment_slip.uploaded.paid_time': 'お支払い時間:',
      'orders.payment_slip.uploaded.upload_time': '証明タイムを更新します:',
      'orders.payment_slip.uploaded.order_link': '表示の順序',
      'orders.payment_slip.uploaded_without_login.title':
        'ありがとうございました！ この注文の支払い転送の詳細がアップロードされました',
      'orders.payment_slip.uploaded_without_login.hint':
        'ビューの詳細にしてくださいログイン',
      'orders.payment_slip.footer':
        'あなたが満たされている支払い情報は、順序がチェックし、それが安全に暗号化されているだけです。あなたは簡単にお支払いを完了することができます。',

      'orders.index.no_records.title': 'ご注文がありません。',
      'orders.index.no_records.description':
        'このショップから注文したことのないように見えます。',
      'orders.index.no_records.action': '買い物を始める',
      'orders.index.title': '注文',
      'orders.index.action.view': '表示する',
      'address.fields.address.recipient_name': '受信者のお名前',
      'address.fields.address': '住',
      'address.fields.city': '市',
      'address.fields.postcode': '郵便番号',
      'address.fields.country': '国',

      'orders.fields.subtotal': '小計',
      'orders.fields.total': '合計',
      'order.billing_address.same': 'お届け先住所と同じ',

      'orders.coupons.label': 'クーポンコード',
      'orders.coupons': 'おめでとう！お客様は以下の割引を受ける権利があります',
      'orders.coupons.placeholder': 'クーポンコードを入力してください',
      'orders.promotion_coupons.placeholder':
        'クーポンコードを入力してください',

      'orders.coupons.apply': 'クーポンを適用する',
      'orders.fields.order_discount': '割引が適用された',
      'orders.fields.order_custom_discount': '割引（カスタム）',
      'orders.coupons.invalid': '申し訳ありませんが、クーポンが無効です',
      'orders.coupons.validation.minamount':
        '申し訳ありませんが、ご注文はこのクーポンの必須値を満たしていません‘',
      'orders.coupons.invalid.order':
        'ご注文の前に無効なクーポンコードを削除してください。',

      'orders.actions.title': '注文状況の管理',
      'orders.action.cancel': '注文をキャンセルする',
      'orders.action.confirm': '注文を確認する',
      'orders.action.ship': '発送済みマークをつけ',
      'orders.general.title': 'ご注文の詳細',
      'orders.payment.title': '支払の詳細',
      'orders.delivery.title': '配送の詳細',
      'orders.products.title': '商品の詳細',

      'orders.fields.accept_terms':
        'お客様はお店の利用規約とプライバシーポリシーに同意します',
      'orders.action.checkout': '発注する',

      'orders.fields.delivery_data.tw_simple_711.recipient_name.label':
        '受信者のお名前',
      'orders.fields.delivery_data.tw_simple_711.location_name.label':
        '7-11ショップ名',
      'orders.fields.delivery_data.tw_simple_711.location_code.label':
        '7-11ショップコード',

      'orders.fields.delivery_data.tw_simple_familymart.recipient_name.label':
        '受信者のお名前',
      'orders.fields.delivery_data.tw_simple_familymart.location_name.label':
        'ファミリーマートショップ名',
      'orders.fields.delivery_data.tw_simple_familymart.location_code.label':
        'ファミリーマートショップコード',
      'orders.fields.delivery_data.tw_ezship.location_code.hint': '店舗を探す',
      'orders.fields.delivery_data.tw_ezship.location_code.label': '店舗コード',
      'orders.fields.delivery_data.tw_ezship.location_name.label': '店舗名',
      'orders.fields.delivery_data.tw_ezship.recipient_name.label':
        '受信者のお名前',
      'orders.fields.delivery_data.tw_ezship.sn_id.label': '参照ID',

      // Checkout page error message field name
      'order.customer_name': '顧客名',
      'order.email': '電子メールアドレス',
      'order.customer_email': '顧客のメールアドレス ',
      'order.customer_phone': '顧客の電話番号',
      'order.delivery_data.recipient_name': '受信者のお名前',
      'order.delivery_data.recipient_phone': '受信者の電話番号',
      'order.delivery_address.address_1': '住所',
      'order.delivery_address.city': '城市',
      'order.delivery_address.state': '地区/州/県 ',
      'order.delivery_data.location_code': 'ショップコード ',
      'order.delivery_data.location_name': 'ショップ名 ',
      'order.delivery_data.time_slot': '配送時間',
      'order.delivery_data.scheduled_delivery_date': '到着日',
      'order.delivery_data.time_slot_key': '到着時間スロット',
      'order.invoice.carrier_number': '運送',
      'order.invoice.mailing_address': '住所',
      'order.invoice.tax_id': '納税者番号',
      'order.payment_data.holdername': 'クレジットカード名義人氏',
      'order.payment_data.expiry_date': '有効期限',
      'order.payment_data.cvc': 'CVC ',
      'order.payment_data.credit_card_number': 'クレジットカード番号',
      'delivery.sfexpress.district': 'SFエクスプレスディストリクト ',
      'delivery.sfexpress.store': 'SFエクスプレスロケーション ',
      'delivery_address.region.hk': '領域',
      'delivery_address.district.hk': '地区',
      'delivery_address.region.tw': '都道府県/市',
      'delivery_address.district.tw': '地区',
      'delivery_address.province.vn': 'Province',
      'delivery_address.district.vn': 'District',
      'delivery_address.ward.vn': 'Ward',
      'delivery_address.province.th': 'Province',
      'delivery_address.district.th': 'District',
      'delivery_address.ward.th': 'Sub-district',
      'save_fields.customer_info.birthday': 'お客様の誕生日 ',

      'delivery_options.fields.delivery_type': '配送タイプ',
      'delivery_options.fields.delivery_types.pickup': '集荷',
      'delivery_options.fields.delivery_types.local': '地元',
      'delivery_options.fields.delivery_types.email': 'メール',
      'delivery_options.fields.delivery_types.international': '国際',
      'delivery_options.fields.delivery_types.custom': 'カスタム',
      'delivery_options.fields.delivery_types.tw_simple_711':
        '7-11集荷（台湾のみ）',
      'delivery_options.fields.delivery_types.tw_simple_familymart':
        'ファミリーマート集荷（台湾のみ）',
      'delivery_options.fields.select_date': '到着日',

      'ezship.error.invalid_inpu':
        'もう1つのフィールドが無効または欠落しています',
      'ezship.error.account_not_exis':
        'ezShipアカウントは存在しません。商人にご連絡してください',
      'ezship.error.no_permissio': 'ezShipアカウントの権限が不十分です',
      'ezship.error.no_easyba':
        'ezShipアカウントにはEasybagまたはMinibagが利用できません',
      'ezship.error.invalid_stor': '無効な集荷店舗',
      'ezship.error.invalid_amoun':
        '無効な金額。ezShipを使用した最大金額は、1注文につきTWD6000です。',
      'ezship.error.invalid_emai': '無効なメールアドレス',
      'ezship.error.invalid_mobil': '無効な電話番号',
      'ezship.error.invalid_nam': '無効な受信者名',
      'ezship.error.system_erro':
        'ezShipシステムエラー。他の配送方法を選択するか、後でもう一度お試しください。',

      'payments.fields.types.paypal': 'クレジットカードまたはPaypal',
      'payments.fields.types.paypal_express': 'クレジットカードまたはPaypal',
      'payments.fields.types.credit_card': 'クレジットカード',
      'payments.fields.types.bank_transfer': '銀行振込',
      'payments.fields.types.cash_on_delivery': '代金引換',
      'payments.fields.types.free_checkout': '無料のチェックアウト',
      'payments.fields.types.custom': 'カスタム',
      'payments.fields.types.allpay_barcode': 'Allpay経由バーコード',
      'payments.fields.types.allpay_credit': 'Allpay経由クレジットカード',
      'payments.fields.types.allpay_cvs': 'Allpay経由CVS',
      'payments.fields.types.allpay_webatm': 'Allpay経由WebATM',
      'payments.fields.types.asiapay': 'Asiapay経由クレジットカード',
      'payments.fields.types.prizm': 'クレジット・ビザ/マスター',
      'payments.fields.types.esun': 'Esunクレジットカード',

      'payment.cc.expirydate': '有効期限',
      'payment.cc.holdername': 'クレジットカード名義人氏名',
      'payment.cc.number': 'クレジットカード番号（ビザ/マスター）',
      'payment.cc.cvc': 'CVC',

      'payment.cc.expirydate.placeholder': '月/年',
      'payment.cc.holdername.placeholder': 'クレジットカード名義人氏名',
      'payment.cc.holdername.hint': 'カード上の氏名と同じ',
      'payment.cc.number.placeholder': 'クレジットカード番号（スペースなし）',
      'payment.cc.cvc.placeholder': 'CVC',
      'payment.cc.cvc.hint': '3桁の暗証番号がお客様のカードの裏にあります。',
      'payment.action.confirm': '確認する',
      'payment.cc.update.instructions':
        'お使いのクレジットカードを入力して更新してください。',
      'payment.cc.fields.accept_terms':
        '私は利用規約とプライバシーポリシーに同意します',
      'payment.cc.fields.accept_terms.validation.required':
        '続行するには、利用規約に同意する必要があります。',
      'payment.cc.cashier.footer':
        'The payment service is powered by SHOPLINE Payments',
      'payment.cc.tw.cashier.footer':
        'この支払いはSHOPLINEが提供していて、国際カードブランドPCI−DSSの最高レベル認定を取得、安全な支払いを提供いたします。国際と国内が発行したクレジットカードが利用できます。',

      'payments.fields.types.ezship':
        'ezShipを介してストアピックアップで支払い',

      cart: 'ショッピングカート',
      'cart.title': 'ショッピングカート',
      'cart.item.remove': '削除',
      'cart.items.remove': '削除',
      'cart.checkout': 'チェックアウト',
      'cart.empty': 'カートに商品ははっていません。',
      'cart.empty.description':
        'お買い物を続行するにはカートに商品を追加してください。',
      'cart.empty.continue': '今すぐ買い物を続ける！',
      'cart.redeem_gift.has_items_selected':
        '<span>{{total_point}}</span> ポイントが使用され、残りは <span>{{rest_point}}</span> ポイント',
      'cart.promotion.checkout': 'レジに進む',
      'cart.promotion_reminder.item': 'もう{{gap}}件を購入',
      'cart.promotion_reminder.amount': 'もう{{gap}}件を購入',
      'cart.promotion_reminder.multiple_step': 'するとさらに割引がもらえます。',
      'checkout.instructions': 'ご注文の詳細',
      'checkout.fields.email': '顧客の電子メール',
      'checkout.fields.email.hint':
        '注文確認がメールに送信されるので、正しいメールアドレスをご入力してください',
      'checkout.fields.phone.hint':
        'SMSによる配送状況の更新を受けるには、携帯電話番号をご入力してください',
      'checkout.fields.phone.invali': '無効な電話番号',
      'checkout.fields.email.placeholder': '電子メールアドレス',
      'checkout.fields.remarks': 'ショップに備考残す',
      'checkout.fields.remarks.placeholder': 'ショップに注文備考を残す',
      'checkout.fields.delivery_method': '配送方法',
      'checkout.fields.out_of_stock':
        '申し訳ありませんが、在庫がないので、カートから商品を取り出してもう一度チェックアウトしてください',
      'checkout.fields.phone': '連絡先の電話番号',
      'checkout.fields.phone.placeholder':
        '連絡先の電話番号をご入力してください',
      'checkout.fields.add_new': '新しい...を追加する',
      'checkout.fields.select': '一つの...を選択する',

      'checkout.delivery_option.title': '配送先住所',
      'checkout.instalment_amount': '約{{amount}} x {{period}}分割払い',
      'checkout.labels.promotion.free_shipping': '送料無料',
      'checkout.labels.promotion.coupon': 'クーポン',
      'checkout.labels.promotion.promotion': '販売促進',
      'checkout.labels.promotion.membership': '会員の販売促進',

      'checkout.payment_method.title': '支払い',

      'checkout.discount.applied_promotions': '適用された販売促進',
      'checkout.discount.applied_coupons': '適用されたクーポン',

      'checkout.promotion.tags.promotion': '販売促進',
      'checkout.promotion.tags.coupon': 'クーポン',
      'checkout.promotion.tags.membership': '会員お得',
      'checkout.promotion.tags.free_shipping': '送料無料',

      'checkout.payment_condition.title':
        'Still need {{difference}} to meet the checkout conditions!',
      'checkout.payment_condition.description':
        'Before proceeding to checkout, please return to the shopping cart to choose another payment method or continue shopping',
      'checkout.payment_condition.continue_shopping': 'Continue Shopping',
      'checkout.payment_condition.hint':
        'This order must meet the minimum amount in order to proceed to checkout',

      'checkout.payments.apple_pay.not_supported':
        '本機器ははApple Payをサポートしていませんので、以下の<ul><li>をご確認してくださいお使いのAppleアカウントでブラウザにログインしてください。</li>' +
        '<li>Please ADD the payment method to your Apple Pay Account</li><li>Please UPGRADE the device and the browser to the latest version</li></ul>',
      'checkout.payments.google_pay.not_supported':
        '本機器ははGoogle Payをサポートしていませんので、以下の<ul><li>をご確認してくださいお使いのGoogleアカウントでブラウザにログインしてください。</li>' +
        '<li>Please ADD the payment method to your Google Pay Account</li><li>Please UPGRADE the device and the browser to the latest version</li></ul>',
      'checkout.payment_failed':
        'このトランザクションは失敗しました。もう一度お試しください。',
      'checkout.cart_over_limit_error':
        'The maximum number of items is 100, please adjust the quantity and purchase again',

      'checkout.auto_fill.popup.title': 'Enter your phone for fast checkout',
      'checkout.auto_fill.popup.continue': 'Continue',
      'checkout.auto_fill.popup.error': 'Please enter the correct format',
      'checkout.auto_fill.popup.cancel': 'Cancel',
      'checkout.auto_fill.toast.error':
        'If there is no record of the last order detected, the fast checkout data will not be brought in',

      'address.fields.recipient_name': '受信者のお名前',
      'address.fields.address.city': '市',
      'address.fields.address.state': '地区/州/県',
      'address.fields.address.postcode': '郵便番号（該当する場合）',
      'address.fields.address.country': '国',
      'address.fields.address.remarks': '備考',

      'messages.form.send': '送信する',
      'action.add_photo': '写真を追加する',
      'action.send': '送信する',
      'action.load_earlier': '以前をロードする',
      'action.load_more': 'もっとロードする',

      'users.fields.name': '氏名',
      'users.fields.email': 'メール',
      'users.fields.mobile_phone_or_email':
        'メールアドレスまたは携帯電話番号を入力',
      'users.fields.password': 'パスワード',
      'users.fields.name.placeholder': '氏名',
      'users.fields.email.placeholder': 'メール',
      'users.fields.password.placeholder': 'パスワードを入力',
      'users.save.success': '変更内容が保存されました。',
      'users.save.email_verification.success':
        '変更内容が保存されました。確認が完了した後で、メールアドレスが更新されます',
      'users.add.phone': '新しい番号を追加する',
      'users.add.phone.maximum': '最大5つまでの電話番号を追加できます。',
      'users.error.phone.format': '電話番号が無効です',
      'users.error.phone.required': '電話番号は必須です',
      'users.add.delivery_address': '新しい住所を追加する',
      'users.add.delivery_address.maximum':
        '5つまでの住所を追加することができます',
      'users.birthday.faq':
        'オンラインストアで登録して買い物をするには、一定の年齢以上である必要があります。',
      'users.error.not_save_mobile_phone':
        'Please verify this phone number before saving change.',
      'users.error.no_changes': '保存する変更はありません。',
      'users.error.maxlength': '～は長すぎます。',
      'users.signup.male': '男性',
      'users.signup.female': '女性',
      'users.signup.other': '登録しない',
      'users.signup.email': 'メールアドレスで登録する',
      'users.signup.mobile_phone': '携帯電話番号で登録する',
      'users.signup.unconfirmed':
        'ようこそ！アカウントを保護するために、アカウントにログインするには確認が必要です。確認メールは既に{{email}}に送信されています。お客様のメールボックスでご確認してください、ありがとうございました！',
      'users.signup.verification.hint.send':
        '確認メールが送信されます。{{count}}秒後に新しい認証コードを再送信することができます。',
      'users.einvoice_carrier': 'お客様のアカウントにE-請求書をまとめる',

      'users.membership.expiry_date': 'メンバー有効期限日',
      'users.membership.expire.unlimited': '無期限',
      'users.membership.upgrade.next_tier':
        '{{tier_name}}にアップグレードすると',
      'users.membership.upgrade.next_tier.discount':
        '、値段は{{discount/10}}割引になります。',
      'users.membership.spending_within_months':
        '{{months}}ヶ月以内に合計{{spending}}以上の商品を購入してアップグレードする',
      'users.membership.spending_single_purchase':
        '1回のご購入合計が{{spending}}以上になると、アップグレードします。',
      'users.membership.all_tiers.description':
        '会員有効期間で資格更新条件を満たさない場合はダウングレードします。',
      'users.membership.all_tiers': 'すべてのメンバーシップを表示する',
      'users.membership.tier.upgrade.condition':
        '次のVIPレベルにアップグレードする条件',
      'users.membership.tier.upgrade.to_next_level.within':
        '{{month}}ヶ月以内に合計購入で',
      'users.membership.tier.upgrade.gap_dollar':
        'アップグレードまであと{{dollar}}',
      'users.membership.tier.upgrade.completed': 'アップグレード対象',
      'users.membership.tier.expire.date.description':
        '自動更新の時間は支払いが完成した翌日の深夜になります。しかし、注文がキャンセルされ、または返品された場合はアップグレードしません。',
      'users.membership.tier.expire.date.auto_downgrade.description':
        '自動更新の時間は支払いが完成した翌日の深夜になります。しかし、注文がキャンセルされ、または返品された場合はアップグレードしません。',
      'users.membership.tier.extension.date.description':
        'Membership extension will not be effective if order has been cancelled/refunded.',
      'users.membership.tier.shopping_discount': '会員{{discount/10}}割引',
      'users.membership.tier.expire.date': '有効期間',
      'users.membership.tier.expire.month': '{{month}} ヶ月',
      'users.membership.tier.extend.condition': '会員資格更新の条件',
      'users.membership.tier.extend.single_purchase':
        'No single purchase over {{dollar}} within {{month}}-month membership valid period.',
      'users.membership.tier.extend.single_purchase.can_extend':
        'You have a single purchase over {{dollar}} within {{month}}-month membership valid period. Membership extension will be effective on expiry date.',
      'users.membership.tier.extend.top_level.single_purchase':
        'No single purchase over {{dollar}} within {{month}}-month membership valid period.',
      'users.membership.tier.extend.top_level.single_purchase.can_extend':
        'You have a single purchase over {{dollar}} within membership valid period. Congrats! Membership extension will be effective on expiry date.',
      'users.membership.tier.extend.within_interval':
        'Your total purchase within {{month}}-month membership valid period is {{spending}}. {{dollar}} more to extend.',
      'users.membership.tier.extend.within_interval.can_extend':
        'Your total purchase within {{month}}-month membership valid period is over {{dollar}}. Extension will be effective on expiry date.',
      'users.membership.tier.extend.top_level.within_interval':
        '{{dollar}} more to extend',
      'users.membership.tier.extend.top_level.within_interval.can_extend':
        'Congrats! Extension will be effective on expiry date.',
      'users.membership.expire.month_valid': '（有効期間 {{months}} ヶ月)',
      'users.membership.max_level_tier':
        'おめでとうございます！最高級の会員になりました。',
      'users.membership.extension.condition': '会員資格更新の条件',
      'users.membership.extension.status': 'Membership Extend Status',
      'users.membership.all.spending_within_months':
        '{{months}}ヶ月でご購入合計{{spending}}以上',
      'users.membership.all.spending_single_purchase':
        '一回のご購入合計{{spending}}以上',
      'users.membership.tier.extend': 'Eligible to extend <b>{{tier}}</b>?',
      'users.membership.tier.upgrade.to_next_level.single':
        '次のレベルにアップグレード?',
      'users.membership.tier.upgrade.condition.dollar':
        '今は {{dollar}} 以上の注文がありません。',
      'users.membership.tier.upgrade.condition.continue': 'お買い物を続ける。',
      'users.membership.extend_valid_period':
        '会員有効期間内の{{months}}か月で{{ type === "within_interval" ? "お買い上げ総額" : "１回のお買い上げ金額" }}{{spending}}以上',
      'users.membership.offer.discount': '会員',
      'member_referral.discount': 'メンバー紹介キャンペーン',
      'member_referral.code.copy_and_share':
        '友人を紹介すると、両方がストアクレジットもらえる',
      'member_referral.rules': 'キャンペンルール',
      'member_referral.code.remarks': 'キャンペンルール',
      'member_referral.generate.code': '紹介リンクを生成',
      'member_referral.copy.link': 'リンクをコピーする',
      'users.edit_member_info': 'メンバープロフィールの編集',
      'sms_messenger_checkbox.subscribe': 'SMSで送信',
      'line_messenger_checkbox.subscribed': 'で送信',
      'facebook_messenger_checkbox.turn_off': 'オフ',
      'line_messenger_checkbox.button.unsubscribe': 'オフ',
      'promotions.page.redeem_gift.end_at':
        '<b class="local-datetime">{{date}}</b>までキャンペーン終了',
      'promotions.page.display_local_timezone':
        '自分のタイムゾーンに表示する<br/>(GMT{{offset}})',

      'membership.profile.subscriptions.orders.update.status':
        'My注文に更新する',
      'membership.profile.subscriptions.orders.comments.new':
        'My注文上の商人からの新しいコメント',
      'membership.profile.subscriptions.messages.new':
        '商人からの新しいメッセージ',
      'membership.profile.subscriptions.marketing.news':
        'ストアの更新と販売促進',
      'membership.profile.subscriptions.none': 'なし',
      'membership.profile.mobile.number.verified':
        '携帯電話番号が確認され、以前に保存した電話番号が置き換えられます。',
      'membership.profile.check.code.error':
        'チェックコードが間違っています！再入力するか、チェックコードSMSを再送信してください',

      'store_credits.title': 'ポイント',
      'store_credits.credit_balance': '金券残高',
      'store_credits.credit_history': '金券履歴',
      'store_credits.fields.date': '日付',
      'store_credits.fields.remarks': '金券変更の理由',
      'store_credits.fields.value': '金券運動',
      'store_credits.fields.expiry_date': '有効期限',
      'store_credits.fields.balance': '金券残高',
      'store_credits.fields.order_prefix': '注文に金券の使用',
      'store_credits.fields.welcome_credit': '最初登録の金券',
      'store_credits.fields.birthday_credit': '誕生日金券',
      'store_credits.fields.user_credit_expired': '金券期限切れ',
      'store_credits.fields.never_expires': '無期限',
      'store_credits.fields.never_expires_point': 'Points Never Expired',
      'store_credits.fields.display.never_expired': 'Never Expired',
      'store_credits.fields.auto_reward_prefix': 'Order ',
      'store_credits.fields.auto_reward': ' gets reward credits',
      'store_credits.fields.revert_credit':
        'キャンセルされた注文から戻った金券',
      'store_credits.fields.revert_credit.return':
        'Credit Reverted from Returned order',
      'store_credits.fields.revert_credit.order_split':
        'Credits reverted from split order',
      'store_credits.fields.revert_credit.order_edit':
        'Credits reverted from edited order',
      'store_credits.fields.revert_credit.return_order_revert':
        'Reverted credits; from return',
      'store_credits.no_data': '金券記録なし',

      'error.404.titl': '申し訳ありません',
      'error.404.descriptio': 'このページは存在しません',
      'error.401.titl': '申し訳ありません',
      'error.401.descriptio':
        'お客様はこのページにアクセスする権限がありません',
      'error.403.titl': '申し訳ありません',
      'error.403.descriptio':
        'お客様はこのページにアクセスする権限がありません',
      'error.403.not_signed_in.messag': 'ログインしていない可能性があります。',
      'error.403.not_signed_in.ancho': 'ログイン',
      'error.continue': '今すぐ買い物を続ける！',

      'orders.comments.title': 'ショップと顧客からのコメント',
      'orders.comments.text.name': 'コメント',
      'orders.comments.text.placeholder': 'この注文のコメントを入力する',

      'orders.fields.payment_fee': '支払い手数料',

      'orders.invoices.title': '請求書',
      'orders.invoices.carrier': '一般的な運送業者',
      'orders.invoices.carrier_type.member': '会員',
      'orders.invoices.carrier_type.mobile_barcode': 'モバイルバーコード',
      'orders.invoices.carrier_type.npc_barcode': 'NPCバーコード',
      'orders.invoices.get_invoice': '領収書が必要です',

      'orders.notifications.title': '注文更新通知',

      'order.payments.login.confirm':
        'Please log in and go to pay again or update credit card information!',

      'model.credit_card.title': 'お使いのクレジットカード情報',
      'model.credit_card.subtitle':
        '更新するには以下のクレジットカードをご入力してください',
      'model.credit_card.choose_card': 'Fast checkout with your saved card',
      'model.credit_card.use_new_card': 'Use a new card',
      'model.slpayment.security_agreement':
        'The payment is powered by SHOPLINE Payments. SHOPLINE Payments is PCI-DSS Level 1 validated and supports the secure use of domestic ＆ international credit cards.',
      'model.slpayment.recurring_pay_agreement':
        'We’ll use the payment details you just provided for subsequent recurrent payments. By submitting, you agree to the <a href="https://shopline.tw/about/product_subscription_agreements_en" target="_blank" rel="noopener">Term Purchase bank card Withholding Agreement.</a>',
      'model.update_credit_card_for_product_subscription.success':
        'Credit card information updated successfully',
      'model.update_credit_card_for_product_subscription.fail':
        'The payment information for Product Subscription is invalid. Please try again.',

      'facebook_messenger_checkbox.subscribed':
        '{{topic}} via <img src="https://{{image_host}}/assets/misc/fb_messenger_36x.png" class="fb-app-icon">メッセンジャーに送る',
      'facebook_messenger_checkbox.topics.ORDER_STATUS_UPDATE,DELIVERY_STATUS_UPDATE':
        '注文更新通知',
      'facebook_messenger_checkbox.topics.MARKETING':
        'ショップの更新と販売促進',

      'media.validation.type': 'jpegとpng形式のみが許可されます',
      'media.validation.error': '画像のアップロード中にエラーが発生しました。',

      'imagepicker.title': '画像がアップロードされた',

      'promotions.section.discount_summary.set.on_item':
        '<b>選択した商品</b>に<b>{{ discount_value }}</b>を受ける',
      'promotions.section.discount_summary.set.on_order':
        '<b>全体の注文</b>に<b>{{ discount_value }}</b>を受ける',
      'promotions.section.discount_summary.discount_value':
        '{{ type == "amount" ?Currency : ""  }}{{ value }}{{ type == "percentage" ? "%":""  }}</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products':
        '<b>選択した商品</b',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal':
        '<b>全体の注文</b',
      'promotions.section.discount_summary.criteria.scope_discounted_products.content':
        '<b>{{ condition_value }}</b>以上の<b>選択した商品</b>の購入で',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal.content':
        '<b>{{ condition_value }}</b>以上の購入で',
      'promotions.section.discount_summary.criteria.type_quantity':
        '{{ count }}のアイテム',
      'promotions.section.discount_summary.criteria.bundle_group':
        'Selected Product: Buy {{ red_count }} in <span class="bundle-group-label bundle-group-red">Group A</span> & {{ green_count }} in <span class="bundle-group-label bundle-group-green">Group B</span> for {{ total }}.',
      'promotions.section.discount_summary.criteria.bundle_group.is_accumulated':
        ' Buy more, Save more!',
      'promotions.section.discount_summary.criteria.bundle_group.is_not_accumulated':
        ' Limited offer for your first set.',
      'promotions.section.discount_summary.content':
        '{{ benefits }}{{ criteria }}',
      'promotions.section.discount_percentage.caption': '{{ value/10 }} 割引',

      'product.page.title.product-info': '商品',
      'product.page.quantity': '数量',
      'product.page.unit_price': '価格（税込）',
      'product.page.subtotal': '小計',
      'product.page.add_to_cart_count':
        '<span class="order-detail-count">{{count}}</span>のアイテムをすでに追加した',

      'products.quick_cart.quantity': '数量',
      'products.quick_cart.preorder_now': '今すぐ予約する',
      'products.quick_cart.add_to_cart': 'カートに入れる',
      'products.quick_cart.quantity_invalid': '',
      'products.quick_cart.out_of_stock': '完売',
      'products.quick_cart.out_of_stock_hint':
        '在庫なし。\n商品はカートに追加されません。',
      'products.quick_cart.low_stock_hint':
        '在庫なし。\n商品の数量を調整してください。',
      'products.quick_cart.reached_max_purchase_quantity':
        '注文ごとに{{ message }}を制限する。',
      'products.quick_cart.quantity_of_stock_hint':
        '{{message}}のアイテムしか在庫ありません',
      'products.quick_cart.messagetobuy':
        '注文の詳細については店主にご連絡ください。',
      'products.quick_cart.message': 'メッセージ',
      'products.quick_cart.show_more': '詳細について',
      'products.message.title': 'このアイテムを削除してもよろしいですか？',
      'products.message.button_cancel': 'キャンセル',
      'products.message.button_ok': 'OK',
      'products.purchase_limit_hint': 'Limit {{limit}} per customer',
      'purchase_limit_hint.purchasable':
        'Limit {{limit}} per customer. You can only purchase {{purchasable_qty}} more.',
      'products.purchase_limit_hint_can_not_purchase':
        'Limit {{limit}} per customer. You have already purchased to limit.',

      'products.category.advance_filter.title': 'フィルタ',
      'products.category.advance_filter.clear': 'クリア',
      'products.category.advance_filter.clear_all': 'すべてクリア',
      'products.category.advance_filter.apply': 'フィルタを適用',
      'products.category.advance_filter.apply.hint':
        '最初にフィルターオプションを選択してください',
      'products.category.advance_filter.show_more': 'もっと見せる',
      'products.category.advance_filter.show_less': '以下の表示',
      'products.category.advance_filter.type.color': 'カラー',
      'products.category.advance_filter.type.size': 'サイズ',
      'products.category.advance_filter.type.brand': 'ブランド',
      'products.category.advance_filter.type.material': '素材',
      'products.category.advance_filter.type.price': '価格 ({{ currency }})',
      'products.category.advance_filter.tag.price_range':
        '{{ min_price }} ~ {{ max_price }}',
      'products.category.advance_filter.tag.min_price': '≥ {{ min_price }}',
      'products.category.advance_filter.tag.max_price': '≤ {{ max_price }}',
      'products.category.advance_filter.placeholder.min_price': '最小額',
      'products.category.advance_filter.placeholder.max_price': '最大金額',

      'product_review_entry.review_description_title':
        '商品レビューを書いてください。',
      'product_review_entry.review_description_content':
        'ご購入はいかがでしょうか？お客様のレビューを教えてください。',
      'product_review_comments.orders.back_to_my_account':
        '< マイアカウントに戻る',
      'product_review_comments.orders.orders_to_view': 'レビューへ受注',
      'product_review_comments.orders.write_a_review': 'レビューを書く',
      'product_review_comments.orders.order_id': 'ご注文ID',
      'product_review_comments.orders.purchased_date': '購入日',
      'product_review_comments.orders.comment': 'コメント',
      'product_review_comments.orders.review': 'Review',
      'product_review_comments.orders.max_length_of_characters': '2000文字以内',
      'product_review_comments.orders.comment_placeholder':
        'ご購入はいかがでしょうか？あなたのレビューを教えてください。',
      'product_review_comments.orders.send': '送信',
      'product_review_comments.orders.finished_review.title':
        'レビューありがとうございます！',
      'product_review_comments.orders.rate_other_order': 'レートその他のご注文',
      'product_review_comments.orders.upload_image': 'Upload image (maximum 9)',
      'product_review_comments.orders.completed_all_reviews':
        'おめでとう！レビューを完成した！',
      'product_review_comments.orders.back_to_the_store': 'ストアに戻る',

      'coupon.valid': '有効',
      'coupon.invalid': '無効',
      'coupon.valid_until':
        '<span class="coupon-period-time"><div class="coupon-period-tip">あなたのタイムゾーンを表示<br/>(GMT{{timeZone}})</div> {{time}} </span>で終了',
      'coupon.one_time': '一度',
      'coupon.first_shoppers': '最初の{{number}}名購入者を限定',
      'coupon.reach_limit': 'リーチの制限',
      'coupon.expired': '期限切れ',
      'coupon.no_valid_coupon': '有効なクーポンはない',
      'coupon.no_invalid_coupon': '無効なクーポンはない',
      'coupon.comingSoon': 'Coming Soon',
      'coupon.retail_store_only': 'Retail Store Only',
      'coupon.online_store_only': 'Online Store Only',
      'coupon.online_and_selected_retail_store_only':
        'Online Store/Selected Retail Store Only',
      'coupon.selected_retail_store_only': 'Selected Retail Store Only',
      'coupon.selected_retail_store_list': 'List of Selected Retail Store',
      'coupon.login_to_receive': 'Login to receive coupon',
      'coupon.login_or_register':
        'Please click the button to login or register',
      'coupon.usage_limit': 'Coupon usage limit has been reached',
      'coupon.already_taken': 'You have already got this coupon',
      'coupon.is_expired': 'Coupon is expired',
      'coupon.not_eligible': 'Not eligible for coupon',
      'coupon.login': 'Login',
      'coupon.close': 'Close',
      'coupon.success': 'Success',
      'coupon.congratulation': 'Congratulations!',
      'coupon.view': 'View Coupons',

      'member_points.rule': 'ポイントについて',
      'member_points.equal_cash': 'イコール{{money}}',
      'member_points.redeem_cash_description':
        'ご購入 {{costMoney}}ごとに{{earnPoint}}ポイント貯まります。貯めたポイントは{{costPoint}}ポイント{{discountMoney}}相当でご利用できます。',

      'promotions.page.term.title': '契約条件',
      'product.member_only': 'メンバー限定',

      'product.product_review.reviews': '件の評価',
      'product.product_review.stars': '星',
      'product.product_review.out_of_5_stars': '星五つ中の',
      'product.product_review.rating_source_shopline': '本店からの評価',
      'product.product_review.rating_source_amazon': 'Amazonからの評価',

      'hk_sfplus.region': '領域',
      'hk_sfplus.area': '領域',
      'hk_sfplus.district': '地区',
      'hk_sfplus.address': '{{region}}, {{district}}, {{area}}',

      'tcat.time_slot.title': '到着時間スロット',
      'tcat.time_slot.01': '13:00前まで',
      'tcat.time_slot.02': '14:00 - 18:00',
      // 03 key is no longer used according to tcat spec
      'tcat.time_slot.04': '任意のタイムスロット',
      'verification.email.trial_merchant.reach_limit.failure':
        'The verification email is not available during the trial operation of the store. If needed, please contact the store for assistance.',
    });
  },
]);
