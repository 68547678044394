import { isEmpty } from 'lodash-es';
import {
  NOTIFICATION_TYPE_TOPIC_MAP,
  PLATFORM,
  SUBSCRIPTION_STATUS_TYPE,
  TOPIC,
} from '../../constants/benchat';
app.directive('lineMessengerCheckbox', [
  '$q',
  'mainConfig',
  '$filter',
  'benchatService',
  'pnotifyService',
  'browserService',
  function (
    $q,
    mainConfig,
    $filter,
    benchatService,
    pnotifyService,
    browserService,
  ) {
    return {
      restrict: 'A',
      templateUrl: require('../../../../../public/themes/v1/default/views/templates.line-messenger-checkbox.html'),
      scope: {
        benchatChannel: '=',
        customerId: '@',
        sessionId: '@',
        notificationType: '@',
        isRevamp: '=',
      },
      link: function (scope) {
        scope.customerIdentifier = scope.customerId
          ? scope.customerId
          : scope.sessionId;
        scope.userRef = benchatService.generateUserRef(
          scope.customerIdentifier,
        );
        scope.topics = NOTIFICATION_TYPE_TOPIC_MAP[scope.notificationType];
        scope.subscriptions = [];
        const updateUserRef = function () {
          scope.userRef = benchatService.generateUserRef(
            scope.customerIdentifier,
          );
        };

        const createLineLoginWindow = function () {
          const url = benchatService.getLineMessageSubscriptionPopupUri(
            scope.benchatChannel.platformChannelId,
            scope.userRef,
            scope.notificationType,
          );
          scope.newWindowRef.location = url;
          listenLineLoginPopupClose();
        };

        const getWindowSize = function () {
          // For centering window, 612 and 600 is following Facebook`s size
          let top = window.screen.height - 612;
          top = top > 0 ? top / 2 : 0;

          let left = window.screen.width - 600;
          left = left > 0 ? left / 2 : 0;

          const width = 600;
          const height = 612;

          const windowSize = `width=${width},height=${height},top=${top},left=${left}`;

          return windowSize;
        };

        const getSubscriptions = function () {
          return benchatService
            .getSubscriptions(
              scope.customerIdentifier,
              scope.topics,
              PLATFORM.LINE_MES_API,
            )
            .then(function (result) {
              const subscriptions = result.data.subscriptions;
              if (isEmpty(subscriptions)) {
                return [];
              }
              return subscriptions.filter(
                (subscription) => subscription.isActive,
              );
            });
        };

        const listenLineLoginPopupClose = function () {
          const timer = setInterval(function () {
            if (scope.newWindowRef.closed) {
              clearInterval(timer);
              benchatService
                .getSubscriptions(
                  scope.customerIdentifier,
                  scope.topics,
                  PLATFORM.LINE_MES_API,
                )
                .then((response) => {
                  const subscriptionStatus = benchatService.getSubscribeStatus(
                    response.data.subscriptions,
                    scope.userRef,
                  );
                  let message = '';
                  switch (subscriptionStatus) {
                    case SUBSCRIPTION_STATUS_TYPE.SUCCESS:
                      message = 'subscribe.success';
                      break;
                    case SUBSCRIPTION_STATUS_TYPE.SOCIAL_USER_DOUBLE_BIND_ERROR:
                      message = 'subscribe.failure.already_bound';
                      break;
                    case SUBSCRIPTION_STATUS_TYPE.PLATFORM_USER_ID_SIGNED_UP_BY_OTHERS_ERROR:
                      message = 'subscribe.line.failure.already_used';
                      break;
                    case SUBSCRIPTION_STATUS_TYPE.SOCIAL_USER_BIND_ERROR:
                      message = 'subscribe.failure';
                      break;
                    case SUBSCRIPTION_STATUS_TYPE.PLATFORM_ID_DIFFERENT_ERROR:
                      message = 'subscribe.line.failure.different_id';
                      break;
                    default:
                      message = 'subscribe.line.failure';
                  }
                  const isError =
                    subscriptionStatus !== SUBSCRIPTION_STATUS_TYPE.SUCCESS;
                  if (isError) {
                    updateUserRef();
                  } else {
                    scope.subscriptions = response.data.subscriptions;
                  }
                  pnotifyService.notify(
                    $filter('translate')(message),
                    isError
                      ? {
                          customClass: 'error',
                          icon: 'fa fa-exclamation-triangle',
                        }
                      : {},
                  );
                });
            }
          }, 1000);
        };

        const useLineLoginPop = function () {
          scope.newWindowRef = window.open('', 'LINE LOGIN', getWindowSize());
          if (scope.isRevamp) {
            return createLineLoginWindow();
          }
          createSubscription()
            .then(function () {
              // subscriptions successfully created
              createLineLoginWindow();
              return getSubscriptions();
            })
            .then(function (activeSubscriptions) {
              scope.subscriptions = activeSubscriptions;
            });
        };

        const createLineLoginRedirect = function () {
          const url = benchatService.getLineMessageSubscriptionRedirectToUri(
            scope.benchatChannel.platformChannelId,
            scope.userRef,
            scope.notificationType,
          );
          return window.location.replace(
            `${url}&shopRedirectUri=${window.location.pathname}`,
          );
        };

        const useLineLoginRedirection = function () {
          if (scope.isRevamp) {
            return createLineLoginRedirect();
          }
          createSubscription()
            .then(function () {
              // subscriptions successfully created
              return createLineLoginRedirect();
            })
            .then(function (activeSubscriptions) {
              scope.subscriptions = activeSubscriptions;
            });
        };

        const createSubscription = function () {
          const subscriptions = _.map(scope.topics, function (topic) {
            return {
              userId: scope.customerIdentifier,
              channelOwnerId: mainConfig.merchantData._id,
              userRef: scope.userRef,
              platform: PLATFORM.LINE_MES_API,
              topic: topic,
            };
          });

          subscriptions.push({
            userId: scope.customerIdentifier,
            channelOwnerId: mainConfig.merchantData._id,
            userRef: scope.userRef,
            platform: PLATFORM.LINE_LOGIN,
            topic: TOPIC.USER_PROFILE,
          });

          return benchatService.patchSubscriptions(subscriptions);
        };

        scope.handleSubscription = function () {
          if (browserService.isInAppBrowser()) {
            useLineLoginRedirection();
          } else {
            useLineLoginPop();
          }
        };

        scope.handleDeactivateSubscription = function () {
          const tasks = scope.subscriptions.map(function (subscription) {
            return benchatService.deactivateSubscription(subscription._id);
          });

          $q.all(tasks)
            .then(function () {
              pnotifyService.notify(
                $filter('translate')('unsubscribe.success'),
                {},
              );
              updateUserRef();
              return getSubscriptions();
            })
            .catch(function () {
              pnotifyService.notify(
                $filter('translate')('unsubscribe.failure'),
                {
                  customClass: 'error',
                  icon: 'fa fa-exclamation-triangle',
                },
              );
              return getSubscriptions();
            })
            .then(function (activeSubscriptions) {
              scope.subscriptions = activeSubscriptions;
            });
        };

        const init = function () {
          getSubscriptions().then(function (activeSubscriptions) {
            scope.subscriptions = activeSubscriptions;
          });
        };

        init();
      },
    };
  },
]);
