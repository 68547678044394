app.config([
  '$translateProvider',
  function ($translateProvider) {
    $translateProvider.translations('zh-tw', {
      'languages.en': 'English',
      'languages.zh-hant': '中文',
      'languages.jp': '日本語',
      'languages.id': 'bahasa Indonesia',

      'form.action.delete': '刪除',

      'form.validation.required': '{{field_name}} 是必須的',
      'form.validation.maxlength': '{{field_name}} 太長',
      'form.validation.minlength': '{{field_name}} 太短',
      'form.validation.invalid': '{{field_name}} 無效',
      'form.validation.pattern': '{{field_name}} 無效',
      'form.validation.pattern.recipient-name':
        '不允許輸入空白與符號，或超過 {{limit_length}} 個字',
      'form.validation.pattern.recipient-phone.TW':
        '{{field_name}} 限制輸入10碼數字',
      'form.validation.pattern.recipient-phone.fix_size':
        '收件人電話號碼 限制輸入 {{ max_count }} 碼數字',
      'form.validation.pattern.recipient-phone.above_size':
        '收件人電話號碼 限制輸入 {{ max_count }} 碼（含）以上數字',
      'form.validation.pattern.recipient-phone.beginning_stint':
        '收件人電話號碼 限制開頭須為 {{ stint_charts.split(",").join(" 或 ") }}',
      'form.validation.email': '{{field_name}} 無效',
      'form.validation.id_no': '提醒：若未填寫身分證等實名資訊，可能影響報關',
      'form.validation.passport':
        '提醒：若未填寫護照號碼等實名資訊，可能影響報關',
      'form.validation.unique': '{{field_name}} 已被選用，請重新輸入。',
      'form.validation.confirmation': '密碼不一樣',
      'form.validation.policy': '你必須同意細則和私隱條款',
      'form.validation.delivery_option.outlying':
        '您所選擇的送貨方式無法運送至離島地區，請更換台灣本島地址或選擇其他送貨方式。',
      'form.validation.delivery_option.local':
        '您所選擇的送貨方式無法運送至台灣本島地區，請更換成離島地址或選擇其他送貨方式。',
      'form.validation.delivery_option.country':
        '您所選擇的送貨方式無法運送至該國家/地區，請更換送貨地址或選擇其他送貨方式。',
      'form.validation.delivery_option.region':
        '您所選擇的送貨方式無法運送至該地區，請更換地址或選擇其他送貨方式。',
      'form.validation.delivery_option.cross_border.MY':
        '您所選擇的送貨方式無法運送至{{ area == "west" ? "西" : "東" }}馬，請更換成{{ area == "west" ? "東" : "西" }}馬地址或選擇其他送貨方式。',
      'form.validation.birthday.invalid': '生日日期 無效',
      'form.validation.birthday.invalid.minimum_age_limit':
        '生日日期無效，需大於 {{minimumAgeLimit}} 歲',
      'form.response.load.more.error':
        'Unable to load the data, please try again.',
      'form.validation.email.hint': '請輸入正確的電郵地址',
      'form.validation.card.bin.error':
        '您所選的付款方式不適用於此信用卡，如有需要請聯絡店主。',
      'form.validation.card.bin.error.unknow_scheme':
        '我們無法判斷你的信用卡類別，如有需要請聯絡店主。',
      'dropdown.hint': '請選擇',
      'redirect.go_home_in_seconds': '{{seconds}}秒後回到首頁',

      'session.signup.title': '新用戶註冊',
      'session.terms.facebook': '使用 Facebook 註冊',
      'session.signup.facebook': 'Facebook 登記',
      'sessions.or': '或',
      'session.terms': '登記／註冊表示你同意我們的私隱政策，使用條款及細則。',
      'session.signin.submit': '登入',
      'session.signin.title': '登入',
      'session.signin.facebook': 'Facebook 登入',
      'session.forgot_password': '忘記密碼',
      'session.mobile_signup.go_back': '上一頁',
      'session.mobile_signup.check_mobile.title':
        '請確認您的手機號碼是否輸入正確，我們將傳送簡訊到以下手機號碼',
      'session.mobile_signup.check_mobile.your_number': '您的手機號碼',
      'session.mobile_signup.check_mobile.your_number.hint':
        '請輸入台灣手機號碼 (09開頭)',
      'session.mobile_signup.check_mobile.send_code': '寄發驗證碼',
      'session.mobile_signup.check_mobile.go_back.1': '若手機號碼有誤，請回',
      'session.mobile_signup.check_mobile.go_back.2': '上一步',
      'session.mobile_signup.check_mobile.go_back.3': '修改',
      'session.mobile_signup.check_mobile.go_back.3.simplified': '修改',
      'session.mobile_signup.input_code.hint': '請輸入收到的簡訊驗證碼',
      'session.mobile_signup.input_code.hint.simplified':
        '請輸入您收到的驗證碼',
      'session.mobile_signup.input_code.placeholder': '驗證碼',
      'session.mobile_signup.input_code.submit': '送出',
      'session.mobile_signup.input_code.resend.countdown':
        '重發驗證碼 ({{resendCountdown}}秒)',
      'session.mobile_signup.input_code.resend.countdown.simplified':
        '重新發送({{resendCountdown}}秒）',
      'session.mobile_signup.input_code.resend': '重發驗證碼',
      'session.mobile_signup.input_code.resend.simplified': '重新發送',
      'session.forget_passowrd.check_phone.error': '手機號碼無效，請重新確認',
      'session.mobile_center.check_phone.error': '此手機號碼已註冊過',
      'user.sign_up.email_verification.dialog.title': '驗證信已寄至 {{mail}}',
      'user.sign_up.email_verification.dialog.description':
        '請立即完成收信驗證，即可更新電郵！若沒收到，請再次更改電郵並儲存。',
      'user.sign_up.email_verification.dialog.confirm': '確定',
      'user.sign_up.check_phone.error':
        '此為現有手機號碼，請重新填寫需要更換的號碼。',
      'user.delivery_data.recipient_phone': '收件人電話號碼',
      'user.add_credit_card': '新增信用卡',
      'user.add_credit_card.success': '卡片新增成功',
      'user.add_credit_card.fail': '卡片新增失敗，請重新嘗試',
      'user.add_credit_card.cancel': '取消',
      'user.add_credit_card.submit': '提交',
      'user.add_credit_card.remind':
        '提醒：新增信用卡時，SHOPLINE Payments 將會取得一次 {{ label }} 元的授權紀錄，此筆授權紀錄不會請款。',
      'user.credit_card.system.error': '系統發生異常，請稍後再試',
      'user.delete_credit_card': '您確定要刪除此張卡片？',
      'user.delete_credit_card.description':
        '刪除此信用卡後將無法使用此卡快速支付',
      'user.delete_credit_card.success': '卡片刪除成功',
      'user.delete_credit_card.fail': '刪除失敗，請重新嘗試',
      'user.delete_credit_card.cancel': '取消',
      'user.delete_credit_card.delete': '確定刪除',
      'user.credit_card.expired': '卡片已過期',
      'user.credit_card_list.delete': '刪除',
      'user.credit_card_list.processing': '新增中',

      'product.out_of_stock': '現在缺貨',
      'product.addon_products.label': '加購品',
      'product.bundled_products.label': '任選優惠',
      'product.bundle_group_products.label': 'A+B 組合',
      'product.buyandget.label': '買 X 享 Y',
      'product.gift.label': '贈品',
      'product.addon_products.errors.main_product_out_of_stock':
        '主商品庫存量不足',
      'product.addon_products.errors.reached_max_purchase_quantity':
        '商品購買上限為 {{ message }} 件',
      'product.addon_products.errors.addon_product_out_of_stock':
        '加購品庫存量不足',
      'product.addon_products.errors.larger_addon_product':
        '購買的加購品數量不可大於主商品',
      'product.addon_products.errors.out_of_stock': '{{title}} 庫存量不足',
      'product.addon_products.tips.limit_exceed':
        '調整商品數量時，你所選購的加購品數量不可大於主商品數量。',
      'product.addon_products.add_to_cart.hint': '已經成功加入到購物車',

      'product.available_time_info.start.1': '商品將於 ',
      'product.available_time_info.start.2': ' 開始販售',
      'product.available_time_info.end.1': '商品將於 ',
      'product.available_time_info.end.2': ' 結束販售',

      'product.view_more': '查看更多',
      'product.view_less': '收起內容',

      'product.add_to_cart': '加入購物車',
      'product.add_to_cart_fail': '您的購物車已滿！',
      'product.buy_now': '立即購買',
      'product.buy_now.preorder.hint': '商品預購中',
      'product.buy_together': '一同購買',
      'product.preorder_limit.hint': '購買數量包含現貨及預購商品',
      'product.coming_soon': '即將開賣',
      'product.available_time_over': '販售結束',
      'product.store_stock.check_stock': '查看可取貨門市',
      'product.set.open_variation': '查看商品詳情',

      'product.amazon_product_review.show_more': '查看更多',

      'member.center.load.more': '載入更多',
      'member.center.load.more.error': '未能載入資料，請重新再試。',

      'wishlist.signin_register': '立即登入/註冊',
      'wishlist.messages.remove_error': '無法成功刪除追蹤商品。',
      'back_in_stock.added': '已訂閱貨到通知',
      'back_in_stock.avaiable': '貨到通知我',

      orders: '訂單',
      order: '訂單',
      'orders.fields.billing_address': '賬單地址',
      'orders.fields.order_number': '訂單號碼',
      'orders.fields.order_date': '訂單日期',
      'orders.fields.order_status': '訂單狀態',
      'orders.fields.order_remarks': '顧客訂單備註',
      'orders.fields.customer_name': '顧客名稱',
      'orders.fields.product_subscription_period': '第{{period}}期',
      'orders.fields.status.temp': '訂單處理中',
      'orders.fields.status.pending': '訂單處理中',
      'orders.fields.status.confirmed': '已確認',
      'orders.fields.status.shipped': '已發貨',
      'orders.fields.status.completed': '已完成',
      'orders.fields.status.received': '已收貨',
      'orders.fields.status.cancelled': '已取消',
      'orders.fields.status.returning': '退貨中',
      'orders.fields.status.returned': '已退貨',
      'orders.fields.customer': '顧客',
      'orders.fields.preorder_item': '預購商品',
      'orders.fields.customer.phone': '顧客電話號碼',
      'orders.fields.billto': '賬單',
      'orders.fields.payment_type': '付款方式',
      'orders.fields.payment_instructions': '付款指示',

      'orders.fields.payment_method.validation.limit_maximum.cvs':
        '交易失敗：超商代碼的最高收款金額為NT$20000',
      'orders.fields.payment_method.validation.limit_lowest.cvs':
        '交易失敗：超商代碼的最低收款金額為NT$30',
      'orders.fields.payment_method.validation.limit_maximum.barcode':
        '交易失敗：超商條碼的最高收款金額為NT$20000',
      'orders.fields.payment_method.validation.limit_lowest.barcode':
        '交易失敗：超商條碼的最低收款金額為NT$30',

      'orders.fields.payment_method.validation.limit_exceeded.allpay_cvs':
        '超商代碼單筆收款上限為30,000新台幣',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_barcode':
        '超商條碼單筆收款上限為20,000新台幣',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_webatm':
        '網路ATM單筆收款上限為30,000新台幣',
      'orders.fields.delivery_method': '送貨方式',
      'orders.fields.delivery_description': '送貨方式指示',
      'orders.fields.delivery_fee': '運費',
      'orders.fields.delivery_address': '送貨地址',
      'orders.fields.delivery_address.remarks': '送貨備註',
      'orders.fields.options.blacklist.error':
        '您的商品不適用於此送貨方式或付款方式，請再查看商品資訊，選擇其他選項或分開下單。',
      'address.fields.address': '地址',
      'address.fields.city': '城市',
      'address.fields.postcode': '郵政編號',
      'address.fields.address.state': '地區/州/省份',
      'address.fields.country': '國家/地區',

      'orders.fields.subtotal': '小計',
      'orders.fields.total': '合計',
      'order.billing_address.same': '與送貨地址相同',

      'orders.coupons.label': '優惠券代碼',
      'orders.coupons': '恭喜！你可以享用以下優惠：',
      'orders.coupons.placeholder': '請輸入優惠券代碼',
      'orders.promotion_coupons.placeholder': '請輸入優惠代碼',

      'orders.coupons.apply': '使用',
      'orders.fields.order_discount': '折扣',
      'orders.fields.order_custom_discount': '自訂折扣合計',
      'orders.coupons.invalid': '對不起，你的優惠卷不適用。',
      'orders.coupons.validation.minamount':
        '對不起, 你的訂單未達到最低消費，優惠卷不適用。',
      'orders.coupons.invalid.order': '結帳前請先刪除無效的優惠卷或代碼',

      'orders.actions.title': '管理訂單狀態',
      'orders.action.cancel': '取消訂單',
      'orders.action.confirm': '確認訂單',
      'orders.action.ship': '標示為已發貨',
      'orders.general.title': '訂單資料',
      'orders.payment.title': '付款資料',
      'orders.delivery.title': '送貨／物流資料',
      'orders.products.title': '商品詳情',

      'orders.fields.accept_terms': '我同意細則和私隱條款',
      'orders.fields.accept_terms.validation.required':
        '你必須同意細則和私隱條款',
      'orders.show.message.thankyou.title': '謝謝你的訂單',
      'orders.show.message.thankyou.description': ' ',
      'orders.show.thankyou.continue': '繼續購物！',
      'orders.show.message.shipped.title': '你的訂單已經寄出',
      'orders.show.message.shipped.description': ' ',

      'orders.payment_slip.order_info.amount': '訂單金額',
      'orders.payment_slip.order_info.instructions': '付款說明',
      'orders.payment_slip.order_info.read_more': '查看完整說明',
      'orders.payment_slip.upload_form.title': '完成轉帳後，請上傳轉帳明細',
      'orders.payment_slip.upload_form.hint':
        '確認款項後，我們將盡速安排發貨！',
      'orders.payment_slip.upload_form.notification':
        '想取得最新訂單資訊，請訂閱',
      'orders.payment_slip.upload_form.image_input.title': '上傳轉帳明細',
      'orders.payment_slip.upload_form.image_input.description':
        '請確認圖片清晰，並包含轉帳後五碼、轉帳時間、轉帳金額',
      'orders.payment_slip.upload_form.image_input.change': '點擊更換照片',
      'orders.payment_slip.upload_form.image_input.error': '圖片需小於 10MB',
      'orders.payment_slip.upload_form.paid_time': '選擇付款時間',
      'orders.payment_slip.upload_form.message': '填寫付款備註',
      'orders.payment_slip.upload_form.message.error':
        '長度不可超過 {{ n }} 字',
      'orders.payment_slip.uploaded.alert':
        '謝謝您，已上傳轉帳明細，我們將儘速確認！',
      'orders.payment_slip.uploaded.notification': '訂閱獲得最新訂單資訊',
      'orders.payment_slip.uploaded.title': '以下是您的轉帳明細',
      'orders.payment_slip.uploaded.paid_time': '付款時間:',
      'orders.payment_slip.uploaded.upload_time': '上傳明細時間:',
      'orders.payment_slip.uploaded.order_link': '查看訂單',
      'orders.payment_slip.uploaded_without_login.title':
        '謝謝您！此訂單已上傳付款明細',
      'orders.payment_slip.uploaded_without_login.hint': '您可以登入查看明細',
      'orders.payment_slip.footer':
        '您填寫的付款資訊僅用於訂單對帳並經過加密技術，敬請安心付款。',

      'orders.index.no_records.title': '你沒有任何訂單',
      'orders.index.no_records.description': '你在這個商店沒有訂單。',
      'orders.index.no_records.action': '繼續購物！',
      'orders.index.title': '訂單',
      'orders.index.action.view': '查閱',
      'orders.action.checkout': '前往結帳',

      'orders.fields.delivery_data.tw_simple_711.recipient_name.label':
        '收件人',
      'orders.fields.delivery_data.tw_simple_711.location_name.label':
        '7-11 門市名稱',
      'orders.fields.delivery_data.tw_simple_711.location_code.label':
        '7-11 店號',

      'orders.fields.delivery_data.tw_simple_familymart.recipient_name.label':
        '收件人',
      'orders.fields.delivery_data.tw_simple_familymart.location_code.label':
        '全家服務編號 (不是店舖號)',
      'orders.fields.delivery_data.tw_simple_familymart.location_name.label':
        '全家門市名稱',

      'orders.fields.delivery_data.sl_logistics_yto_store.seven_eleven.label':
        '7-11',
      'orders.fields.delivery_data.sl_logistics_yto_store.family_mart.label':
        '全家',
      'orders.fields.delivery_data.sl_logistics_yto_store.store.label': '門市',
      'orders.fields.delivery_data.sl_logistics_yto_store.city_list.error':
        '送貨方式發生錯誤，請重新整理或<a href="/cart" class="alert-highlight">返回上一頁更換送貨方式</a>',
      'orders.fields.delivery_data.sl_logistics_yto_store.store_dropdown.error':
        '地址發生錯誤，請重新選擇',

      'orders.fields.delivery_data.family_mart_freeze.error.locale_code':
        '配送編號門市是必須的',

      // Checkout page error message field name
      'order.customer_name': '顧客名稱',
      'order.email': '電子信箱',
      'order.customer_email': '顧客電郵',
      'order.customer_phone': '顧客電話號碼',
      'order.delivery_data.recipient_name': '收件人名稱',
      'order.delivery_data.recipient_phone': '收件人電話號碼',
      'order.delivery_data.consignee_id_no': '收件人身份證字號或護照號碼',
      'order.delivery_address.address_1': '地址',
      'order.delivery_address.city': '城市/市鎮',
      'order.delivery_address.postcode': '郵遞區號',
      'order.delivery_address.state': '地區/州/省份',
      'order.delivery_data.location_code': '門市編號',
      'order.delivery_data.location_name': '門市名稱',
      'order.delivery_data.time_slot': '送貨時間',
      'order.delivery_data.scheduled_delivery_date': '選擇指定到貨日期',
      'order.delivery_data.time_slot_key': '選擇指定到貨時段',
      'order.invoice.carrier_number': '條碼',
      'order.invoice.mailing_address': '發票地址',
      'order.invoice.tax_id': '統一編號',
      'order.payment_data.holdername': '持卡人名字 ',
      'order.payment_data.expiry_date': '有效日期',
      'order.payment_data.cvc': '安全碼(CVC)',
      'order.payment_data.credit_card_number': '信用卡號碼',
      'order.billing_address.title': '帳單寄送地址',
      'delivery.sfexpress.district': '順豐地區',
      'delivery.sfexpress.store': '順豐地址',
      'delivery_address.region.hk': '地區',
      'delivery_address.district.hk': '區域',
      'delivery_address.region.tw': '城市/縣',
      'delivery_address.district.tw': '地區',
      'delivery_address.province.vn': '省/市',
      'delivery_address.district.vn': '郡/鎮',
      'delivery_address.ward.vn': '社/坊',
      'delivery_address.province.th': '府',
      'delivery_address.district.th': '縣/郡',
      'delivery_address.ward.th': '區',
      'delivery_address.street': '街道',
      'delivery_address.store': '{{ station_name }}門市',

      'save_fields.customer_info.birthday': '顧客生日日期',

      'delivery_options.fields.delivery_type': '物流類別',
      'delivery_options.fields.delivery_types.pickup': '面交／自取',
      'delivery_options.fields.delivery_types.local': '本地郵寄',
      'delivery_options.fields.delivery_types.email': '電郵',
      'delivery_options.fields.delivery_types.international': '海外郵寄',
      'delivery_options.fields.delivery_types.custom': '自定',
      'delivery_options.fields.delivery_types.tw_simple_711':
        '7-11取貨（只限台灣）',
      'delivery_options.fields.delivery_types.tw_simple_familymart':
        '全家取貨（只限台灣）',
      'delivery_options.fields.select_date': '選擇指定到貨日期',
      'orders.fields.delivery_data.tw_ezship.location_code.hint': '搜尋門市',
      'orders.fields.delivery_data.tw_ezship.location_code.label':
        '收件門市代號',
      'orders.fields.delivery_data.tw_ezship.location_name.label':
        '收件門市名稱',
      'orders.fields.delivery_data.tw_ezship.recipient_name.label': '收件人',
      'orders.fields.delivery_data.tw_ezship.sn_id.label': '店到店編號',

      'delivery_fee.pay.on.delivery': '運費於到貨時向您收取',

      'ezship.error.invalid_input': '內容有誤或欄位短缺',
      'ezship.error.account_not_exist': 'ezShip帳號不存在',
      'ezship.error.no_permission': 'ezShip帳號權限不足',
      'ezship.error.no_easybag': 'ezShip帳號無可用之輕鬆袋或迷你袋',
      'ezship.error.invalid_store': '取件門市有誤',
      'ezship.error.invalid_amount':
        '金額有誤。ezShip單一帳單代收金額上限為新台幣6000元。',
      'ezship.error.invalid_email': '電郵格式有誤',
      'ezship.error.invalid_mobile': '電話號碼格式有誤',
      'ezship.error.invalid_name': '收件人內容有誤或為空值',
      'ezship.error.system_error':
        'ezShip系統錯誤，請選取其他送貨方式或稍後再試。',

      'lock_inventory.locked_quantity_unit': '件',

      'payments.fields.types.allpay_barcode': '超商條碼（經由歐付寶）',
      'payments.fields.types.allpay_cvs': '超商代碼（經由歐付寶）',
      'payments.fields.types.allpay_credit': '信用卡（經由歐付寶）',
      'payments.fields.types.allpay_webatm': '網絡ATM（經由歐付寶）',
      'payments.fields.types.asiapay': '信用卡（經由Asiapay）',
      'payments.fields.types.paypal': '信用卡或Paypal',
      'payments.fields.types.paypal_express': '信用卡或Paypal',
      'payments.fields.types.credit_card': '信用卡',
      'payments.fields.types.bank_transfer': '銀行轉帳／ATM',
      'payments.fields.types.cash_on_delivery': '貨到付款',
      'payments.fields.types.ezship': '超商貨到付款（經由ezShip）',
      'payments.fields.types.free_checkout': '免費結帳',
      'payments.fields.types.custom': '自定付款方法',
      'payments.fields.types.prizm': '信用咭 Visa / Master',
      'payments.fields.types.esun': '玉山信用卡',

      'payment.cc.expirydate': '有效日期',
      'payment.cc.holdername': '持卡人名字',
      'payment.cc.number': '信用卡號碼 (Visa / Master)',
      'payment.cc.cvc': '安全碼(CVC)',
      'payment.cc.expirydate.placeholder': 'MM/YY',

      'payment.cc.holdername.placeholder': '持卡人名字',
      'payment.cc.number.placeholder': '信用卡號碼(不留空格）',
      'payment.cc.cvc.placeholder': '安全碼(CVC)',
      'payment.action.confirm': '確定',
      'payment.cc.update.instructions': '請輸入並更新你的信用卡資料。',
      'payment.cc.fields.accept_terms': '我同意細則和私隱條款。',
      'payment.cc.fields.accept_terms.validation.required':
        '你必須同意細則和私隱條款。',
      'payment.cc.cashier.footer': '金流服務由SHOPLINE Payments提供',
      'payment.cc.tw.cashier.footer':
        '本金流服務由 SHOPLINE Payments 提供，通過 PCI-DSS 國際信用卡組織最高等級認證，提供安全的交易服務，支援國內外信用卡刷卡。',

      cart: '購物車',
      'cart.title': '購物車',
      'cart.clean': '手刀結帳去！',
      'cart.item.remove': '移除',
      'cart.items.remove': '移除',
      'cart.checkout': '訂單結帳',
      'cart.over_limit':
        '購物車商品數量超出 <span class="limit-number">{{limit_number}}</span> 件的上限，請先結帳後再加入商品！同時，您也可以登入會員將此商品加入追蹤清單。',
      'cart.over_limit.login':
        '購物車商品數量超出 <span class="limit-number">{{limit_number}}</span> 件的上限，請先結帳後再加入商品！同時，您也可以將此商品加入追蹤清單。',
      'cart.empty': '你的購物車是空的',
      'cart.empty.description': '記得加入商品到你的購物車',
      'cart.empty.continue': '繼續購物！',
      'cart.promotion.checkout': '前往購物車',
      'cart.promotion.has_items_selected': '已選 <b>{{quantity}}</b> 件',
      'cart.promotion.bundle_group.has_items_selected':
        'A 組已選 <b style="color: #f05c53">{{red_quantity}}</b> 件{{red_reminder}}，B 組已選 <b style="color: #1ba462">{{green_quantity}}</b> 件{{green_reminder}}{{applied_reminder}}',
      'cart.promotion.bundle_group.has_items_selected.mobile':
        'A 組 <b style="color: #f05c53">{{red_quantity}}</b> 件，B 組 <b style="color: #1ba462">{{green_quantity}}</b> 件',
      'cart.redeem_gift.has_items_selected':
        '兌換贈品 <span>{{total_point}}</span> 點，剩 <span>{{rest_point}}</span> 點可兌換',
      'cart.redeem_gift.has_items_selected.insufficient_point':
        '已超出可兌換點數 <span>{{rest_point}}</span> 點，請移除部分贈品',

      'cart.promotion_reminder.separator': '，',
      'cart.promotion_reminder.group_red':
        '<span class="bundle-group-label bundle-group-red">A 組</span> 還差 {{gap}} 件',
      'cart.promotion_reminder.group_green':
        '<span class="bundle-group-label bundle-group-green">B 組</span> 還差 {{gap}} 件',
      'cart.promotion_reminder.group_gap': ' (還差 {{gap}} 件)',
      'cart.promotion_reminder.item': '再買 {{gap}} 件 ',
      'cart.promotion_reminder.amount': '再買 {{gap}} ',
      'cart.promotion_reminder.multiple_step': '省更多',
      'cart.promotion_reminder.multiple_step.reward_campaign': '回饋更多！',
      'cart.promotion_reminder.stackable': '買越多省越多！',
      'cart.promotion_reminder.applied.free_shipping': '恭喜享有免運',
      'cart.promotion_reminder.applied.gift': '恭喜享有贈品',
      'cart.promotion_reminder.applied.discount_prefix': '，',
      'cart.promotion_reminder.applied.discount': '恭喜享有優惠，',
      'cart.promotion_reminder.applied.reward_campaign': '恭喜享有優惠，',
      'cart.promotion_reminder.applied_highest.free_shipping': '恭喜享有免運！',
      'cart.promotion_reminder.applied_highest.gift': '恭喜享有贈品！',
      'cart.promotion_reminder.applied_highest.discount': '恭喜享有優惠！',
      'cart.promotion_reminder.applied_highest.reward_campaign':
        '恭喜享有優惠！',
      'cart.promotion_reminder.discount_target.free_shipping': '即享有免運！',
      'cart.promotion_reminder.discount_target.gift': '即享有贈品！',
      'cart.promotion_reminder.discount_target.reward_campaign': '即享有優惠！',
      'cart.promotion_reminder.discount_target.discount': '即享有優惠！',

      'checkout.instructions': '訂單資料',
      'checkout.fields.email': '你的電郵',
      'checkout.fields.email.hint':
        '請確保正確填入電郵，所有訂單事宜（確認、查詢）都會傳送至您的電郵。',
      'checkout.fields.phone.hint': '請輸入手機號碼，以接收物流短訊通知',
      'checkout.fields.email.placeholder': '電郵',
      'checkout.fields.phone': '你的電話號碼',
      'checkout.fields.phone.placeholder': '電話號碼',
      'checkout.fields.remarks': '訂單備註',
      'checkout.fields.remarks.placeholder': '請填寫備註給店主',
      'checkout.fields.delivery_method': '送貨方式',
      'checkout.delivery_option.title': '送貨地址',
      'checkout.delivery_option.out_of_stock':
        '部分商品庫存不足，無法選擇此門市，請嘗試更換其他門市',
      'checkout.payment_method.title': '付款資料',
      'checkout.fields.out_of_stock':
        '對不起，所選的貨品存貨不足，請先移除然後再下單',
      'checkout.fields.add_new': '新增',
      'checkout.fields.select': '--選擇--',
      'checkout.instalment_amount': '大約為{{amount}} x {{period}}期',
      'checkout.labels.promotion.free_shipping': '免運',
      'checkout.labels.promotion.coupon': '優惠券',
      'checkout.labels.promotion.promotion': '優惠促銷',
      'checkout.labels.promotion.membership': '會員優惠',

      'checkout.discount.applied_promotions': '已享用之優惠',
      'checkout.discount.applied_coupons': '已享用之優惠',

      'checkout.promotion.tags.promotion': '優惠促銷',
      'checkout.promotion.tags.coupon': '優惠券',
      'checkout.promotion.tags.membership': '會員優惠',
      'checkout.promotion.tags.free_shipping': '免運',
      'checkout.promotion.tags.subscription_promotion': '定期購優惠',

      'checkout.payment_condition.title': '還差 {{difference}}！',
      'checkout.payment_condition.description':
        '才能進行結帳，請返回購物車選擇其他付款方式或者繼續購物',
      'checkout.payment_condition.continue_shopping': '繼續購物',
      'checkout.payment_condition.hint': '此訂單須符合最低金額才能進行結帳',

      'checkout.payments.apple_pay.not_supported':
        '無法以 Apple Pay支付：<ul><li>請確認裝置或瀏覽器是否已登入 Apple 帳戶</li><li>請確認 Apple Pay 帳戶內是否已綁定有效卡號</li>' +
        '<li>請確認裝置或瀏覽器是否為最新版本且支援 Apple Pay</li></ul>',
      'checkout.payments.google_pay.not_supported':
        '無法以 Google Pay支付：<ul><li>請確認裝置或瀏覽器是否已登入 Google 帳戶</li><li>請確認 Google Pay 帳戶內是否已綁定有效卡號</li>' +
        '<li>請確認裝置或瀏覽器是否為最新版本且支援 Google Pay</li></ul>',
      'checkout.payment_failed': '付款失敗，請重新再試',
      'checkout.payment_failed.atome.minamount':
        '訂單金額不符付款方式限額，請調整購物車內容或切換其他付款方式',
      'checkout.payment_failed.check_credit_card_info':
        '請先確認卡片資訊，如確認輸入資料正確，請向發卡行確認卡片授權狀況或聯繫店家',
      'checkout.cart_over_limit_error':
        '商品總數的上限為 100 件，請調整數量再點擊購買',

      'checkout.card_info_error': '請確認信用卡資訊正確後再試一次',
      'checkout.station_space_confirm_error':
        '門市選擇失敗，請再試一次或是聯絡我們',

      'checkout.auto_fill.popup.title': '輸入手機以進行快速結帳',
      'checkout.auto_fill.popup.continue': '繼續',
      'checkout.auto_fill.popup.error': '請輸入正確的格式',
      'checkout.auto_fill.popup.cancel': '取消',
      'checkout.auto_fill.toast.error':
        '沒有偵測到上一筆訂單的紀錄，將不會帶入快速結帳資料',

      'address.fields.recipient_name': '收件人',
      'address.fields.address.recipient_name': '收件人',
      'address.fields.address.city': '城市',
      'address.fields.address.postcode': '郵政編號（如適用)',
      'address.fields.address.country': '國家/地區',
      'address.fields.address.remarks': '備註',

      'messages.form.send': '發送',
      'action.add_photo': '加入圖片',
      'action.send': '發送',

      'action.load_earlier': '載入之前',
      'action.load_more': '載入更多',
      'users.fields.name': '用戶名',
      'users.fields.email': '電郵',
      'users.fields.mobile': '手機號碼',
      'users.fields.mobile_phone_or_email': '電郵或手機號碼',
      'users.fields.password': '密碼',
      'users.fields.name.placeholder': '用戶名',
      'users.fields.email.placeholder': '電郵',
      'users.fields.mobile.placeholder': '手機號碼',
      'users.fields.password.placeholder': '密碼',
      'users.save.success': '變更已儲存',
      'users.save.email_verification.success':
        '變更已儲存, 電郵會在驗證成功後更新',
      'users.save.email_verification.send': '驗證信已寄出',
      'users.add.phone': '新增電話號碼',
      'users.add.delivery_address': '新增送貨地址',
      'users.add.phone.maximum': '最多只能有五組電話號碼',
      'users.add.delivery_address.maximum': '最多只能有五個送貨地址',
      'users.error.not_save_mobile_phone': '此手機號碼尚未驗證，無法儲存變更',
      'users.error.no_changes': '沒有任何可儲存的變更',
      'users.signin.error.invalid.mobile': '帳號或密碼是無效的',
      'users.signin.error.invalid.email': '帳號或密碼是無效的',
      'users.signup.male': '男',
      'users.signup.female': '女',
      'users.signup.other': '不透露',
      'users.signup.email': '使用Email註冊',
      'users.signup.mobile_phone': '使用手機號碼註冊',
      'users.signup.unconfirmed':
        '歡迎回來！為保護您的帳號安全，即日起需完成帳號驗證才能登入。驗證信已發送至{{email}}，請前往收信驗證，謝謝您！',
      'users.signup.verification.hint.send':
        '驗證信已寄出，{{count}}秒後可重發驗證信',
      'users.einvoice_carrier': '電子發票載具歸戶',
      'users.edit_member_info': '編輯會員資料',
      'users.membership.member_info.title': '會員資料獎賞',
      'users.membership.member_info.hint':
        '填寫以下會員資訊：{{fields}}，填寫完畢可獲得 {{rewards}}',
      'users.membership.member_info.sent':
        '待資料檢查完成，將發放會員資料獎賞！',
      'users.membership.member_info.symbol': '、',
      'users.membership.member_info.name': '姓名',
      'users.membership.member_info.email': '電郵',
      'users.membership.member_info.mobile': '手機號碼',
      'users.membership.member_info.gender': '性別',
      'users.membership.member_info.birthday': '生日',
      'users.membership.member_info.user_credits': '${{count}} 元購物金',
      'users.membership.member_info.member_points': '{{count}} 點會員點數',
      'users.membership.member_info.coupons': '{{count}} 張優惠券',
      'users.membership.forget_password.email_sent':
        '我們已寄出帳號驗證信至{{email}}，\n請收信以繼續重設密碼！',
      'users.member.normal-level': '一般會員',
      'users.membership.expiry_date': '會籍到期日',
      'users.membership.expire.unlimited': '永久有效',
      'users.membership.expire.month_valid': '（效期 {{months}} 個月)',
      'users.membership.tier.expire.date.description':
        '自動更新時間為訂單完成付款後的隔日凌晨，若因取消訂單或退貨未達成升級條件則不會升級。',
      'users.membership.tier.expire.date.auto_downgrade.description':
        '自動更新時間為訂單轉為已完成後的隔日凌晨，若因取消訂單或退貨未達成升級條件則不會升級。',
      'users.membership.tier.extension.date.description':
        '會籍到期時，訂單狀態若因取消訂單或退貨未達成續會條件則不會續會。',
      'users.membership.offer.discount': '專屬優惠',
      'users.membership.upgrade.next_tier': '升級至{{tier_name}}',
      'users.membership.upgrade.next_tier.discount': '，享購物 {{discount}} 折',
      'users.membership.extension.condition': '續會條件',
      'users.membership.extension.status': '續會狀態',
      'users.membership.max_level_tier': '恭喜您已至最高階會員!',
      'users.membership.spending_within_months':
        '{{months}} 個月內累積消費額達 {{spending}} 即可升級',
      'users.membership.spending_single_purchase':
        '單次消費額達 {{spending}} 即可升級',
      'users.membership.all.spending_within_months':
        '{{months}} 個月內累積消費額達 {{spending}}',
      'users.membership.all.spending_single_purchase':
        '單次消費額達 {{spending}}',
      'users.membership.extend_valid_period':
        '會籍效期{{months}}個月內{{ type === "within_interval" ? "累積" : "單次" }}消費額達 {{spending}}',
      'users.membership.all_tiers': '查看所有會員等級規則',
      'users.membership.all_tiers.condition': '會員等級規則',
      'users.membership.all_tiers.description':
        '會籍期效內若沒達成續會條件，將會自動降級',
      'users.membership.tier.shopping_discount': '購物 {{discount}} 折',
      'users.membership.tier.upgrade.condition': '本階升級條件 :',
      'users.membership.tier.upgrade.condition.dollar':
        '目前沒有高於 {{ dollar }} 的訂單，',
      'users.membership.tier.upgrade.condition.continue': '繼續逛逛！',
      'users.membership.tier.extend': '已達 <b>{{tier}}</b> 續會條件？',
      'users.membership.tier.upgrade.to_next_level.single': '已達升級條件？',
      'users.membership.tier.upgrade.to_next_level.within':
        '{{month}} 個月內累積消費額',
      'users.membership.tier.upgrade.completed': '符合升級資格',
      'users.membership.tier.upgrade.gap_dollar': '差 {{ dollar }} 升級',
      'users.membership.tier.expire.date': '有效期限 :',
      'users.membership.tier.extend.condition': '續會條件 :',
      'users.membership.tier.extend.single_purchase':
        '會籍效期 {{month}} 個月內沒有高於 {{dollar}} 的訂單',
      'users.membership.tier.extend.single_purchase.can_extend':
        '會籍效期 {{month}} 個月內有高於 {{dollar}} 的訂單，到期時將自動續會',
      'users.membership.tier.extend.top_level.single_purchase':
        '會籍內沒有高於 {{dollar}} 的訂單，',
      'users.membership.tier.extend.top_level.single_purchase.can_extend':
        '會籍內有高於 {{dollar}} 的訂單，恭喜達成！到期時將自動續會',
      'users.membership.tier.extend.within_interval':
        '會籍效期 {{month}} 個月內累積消費 {{spending}}，差 {{dollar}} 續會',
      'users.membership.tier.extend.within_interval.can_extend':
        '會籍效期 {{month}} 個月內累積消費已達 {{dollar}}，到期時將自動續會',
      'users.membership.tier.extend.top_level.within_interval':
        '差 {{dollar}} 續會',
      'users.membership.tier.extend.top_level.within_interval.can_extend':
        '恭喜達成！到期時將自動續會',
      'users.membership.tier.expire.month': '{{month}} 個月',

      'membership.profile.subscriptions.orders.update.status':
        'Updates to my orders',
      'membership.profile.subscriptions.orders.comments.new':
        'New comments from the merchant on my orders',
      'membership.profile.subscriptions.messages.new':
        'New messages from the merchant',
      'membership.profile.subscriptions.marketing.news': '商店資訊及優惠方案',
      'membership.profile.subscriptions.none': 'None',
      'membership.profile.mobile.number.verified':
        '會員手機驗證完成，並將取代已儲存的電話號碼以利辨識',
      'membership.profile.check.code.error':
        '驗證碼有誤，請重新輸入或重發驗證碼',
      'membership.profile.verification_hint':
        '若您更改電郵，儲存後需進行驗證，完成驗證後電郵將會立即更新為 {{unconfirmed_email}}',

      'store_credits.title': '商店購物金',
      'store_credits.credit_balance': '現有購物金',
      'store_credits.credit_history': '購物金紀錄',
      'store_credits.fields.date': '日期',
      'store_credits.fields.remarks': '購物金項目',
      'store_credits.fields.value': '購物金款項',
      'store_credits.fields.expiry_date': '到期日',
      'store_credits.fields.balance': '購物金餘額',
      'store_credits.fields.order_prefix': '已使用購物金在此筆訂單',
      'store_credits.fields.welcome_credit': '新加入會員購物金',
      'store_credits.fields.member_referral_credit': '推薦新顧客進行消費',
      'store_credits.fields.member_info_quick_completion_credit':
        '會員資料獎賞購物金',
      'store_credits.fields.welcome_member_referral_credit':
        '透過推薦連結註冊成功',
      'store_credits.fields.birthday_credit': '生日購物金',
      'store_credits.fields.user_credit_expired': '購物金過期',
      'store_credits.fields.never_expires': '永不過期',
      'store_credits.fields.never_expires_point': '點永不過期',
      'store_credits.fields.display.never_expired': '永不過期',
      'store_credits.fields.auto_reward_prefix': '訂單 ',
      'store_credits.fields.auto_reward': ' 獲得購物金',
      'store_credits.fields.revert_credit': '回補購物金；已取消訂單',
      'store_credits.fields.revert_credit.return': '回補購物金；已退貨訂單',
      'store_credits.fields.revert_credit.order_split': '回補購物金；來自拆單',
      'store_credits.fields.revert_credit.order_edit':
        '回補購物金；來自編輯訂單',
      'store_credits.fields.revert_credit.return_order_revert':
        '回補購物金；來自退貨',
      'store_credits.no_data': '沒有任何記錄',
      'store_credits.bulk_imported': '手動新增購物金',

      'member_points.fields.revert_member_point': '回補點數；已取消訂單',
      'member_points.fields.revert_member_point.return': '回補點數；已退貨訂單',
      'member_points.fields.revert_member_point.order_split':
        '回補點數；來自拆單',
      'member_points.fields.revert_member_point.order_edit':
        '回補點數；來自編輯訂單',
      'member_points.fields.revert_member_point.return_order_revert':
        '回補點數；來自退貨',
      'member_points.fields.member_info_reward': '會員資料獎賞會員點數',

      'tax.sales_tax': '消費稅',
      'tax.sales_tax_rate': '消費稅率',
      'tax.service_tax': '服務稅',
      'tax.service_tax_rate': '服務稅率',
      'tax.delivery_tax': '運費稅',
      'tax.delivery_tax_rate': '運費稅率',
      'tax.calculate_tax': '計算稅金',
      'tax.calculate_tax.info.1': '請先按',
      'tax.calculate_tax.info.2': '後再結帳',
      'tax.calculation_title': '稅費計算方式：',
      'tax.calculation_content': '(收稅商品總價 - 優惠) * 稅率 + 運費 * 稅率',
      'tax.price_updated': '價格已更新',
      'tax.total': '合計',
      'tax.fee': '稅費',
      'tax.product_price': '商品價格',
      'tax.discounts': '優惠',
      'tax.applied_store_credit': '折抵購物金',

      'error.404.title': '非常抱歉',
      'error.404.description': '網頁不存在',
      'error.401.title': '非常抱歉',
      'error.401.description': '您沒有權限進入此頁面',
      'error.403.title': '非常抱歉',
      'error.403.description': '您沒有權限進入此頁面',
      'error.403.not_signed_in.message': ', 這可能是因為您還沒有',
      'error.403.not_signed_in.anchor': '登入',

      'error.continue': '繼續購物！',

      'dynamic.delivery.fee.no.state.notice':
        '運費將於填寫送貨地址後，於結帳頁顯示。',
      'dynamic.delivery.fee.no.state.info': '將於確認送貨地址後顯示運費',
      'dynamic.delivery.fee.calculate.title': '運費計算方式',
      'dynamic.delivery.fee.calculate.desc':
        '首 1 公斤運費 + (總重 - 1 公斤) * 續重運費',
      'dynamic.delivery.fee.calculate.hint': '總重未滿 1 公斤以 1 公斤計算',
      'dynamic.delivery.fee.updated.notice': '運費已更新',
      'dynamic.delivery.fee.calculate.info': '運費合計：{{totalDeliveryFee}}',

      'orders.comments.title': '店家和顧客訂單通訊',
      'orders.comments.text.name': '留言',
      'orders.comments.text.placeholder': '請輸入留言',

      'orders.invoices.title': '發票',
      'orders.invoices.carrier': '共同性載具',
      'orders.invoices.carrier_type.member':
        '會員載具 (發票資訊會寄到您的電郵)',
      'orders.invoices.carrier_type.mobile_barcode': '手機條碼',
      'orders.invoices.carrier_type.npc_barcode': '自然人憑證條碼',
      'orders.invoices.get_invoice': '我要索取發票',

      'orders.notifications.title': '訂單狀態通知',

      'facebook_messenger_checkbox.subscribed': '已訂閲',
      'facebook_messenger_checkbox.turn_off': '關閉',
      'facebook_messenger_checkbox.v2.subscribe': '訂閱 Messenger',
      'facebook_messenger_checkbox.v2.subscribed': '已訂閱 Messenger',
      'facebook_messenger_checkbox.topics.ORDER_STATUS_UPDATE,DELIVERY_STATUS_UPDATE':
        '訂單狀態通知',
      'facebook_messenger_checkbox.topics.MARKETING': '商店資訊及優惠方案',

      'whatsapp_messenger_checkbox.subscribe': '訂閱 WhatsApp',

      'orders.fields.payment_fee': '附加費',

      'media.validation.file_limit.one': '只可以上載一張圖片',
      'media.validation.type': '只可以上載JPEG或PNG格式的圖片',
      'media.validation.error.file_size': '請上傳小於5MB的圖片',
      'media.validation.error.file_attachment_size': '請上傳小於2MB的文件',
      'media.validation.error': '對不起，上載出現問題，請重試。',

      'attachment.upload.description':
        '檔案不大於 2MB\n格式可為：pdf, doc, docx, xls, xlsx, csv, jpg, jpeg, png, gif',

      'imagepicker.title': '上載圖片',

      'promotions.section.discount_summary.set.on_item':
        '<b>指定商品</b> {{ discount_value }}',
      'promotions.section.discount_summary.set.on_order':
        '{{ criteria_on ? "" : "<b>全店</b>" }} {{ discount_value }}',
      'promotions.section.discount_summary.discount_value':
        '{{ type == "amount" ? "即減" : "即享" }} <b>{{ type == "amount" ? currency : "" }}{{ value }}{{ type == "percentage" ? "折":"" }}</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products':
        '<b>指定商品</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal':
        '<b>全單</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products.content':
        '購買 <b>指定商品</b> <b>滿{{ condition_value }}</b>，',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal.content':
        '<b>全單</b> 購買 <b>滿{{ condition_value }}</b>，',
      'promotions.section.discount_summary.criteria.type_quantity':
        '{{ count }}件',
      'promotions.section.discount_summary.criteria.bundle_group':
        '指定商品：<span class="bundle-group-label bundle-group-red">A 組</span> 任選 {{ red_count }} 件，<span class="bundle-group-label bundle-group-green">B 組</span> 任選 {{ green_count }} 件 共 {{ total }} 元',
      'promotions.section.discount_summary.criteria.bundle_group.is_accumulated':
        '，買越多省越多！',
      'promotions.section.discount_summary.criteria.bundle_group.is_not_accumulated':
        '，最多優惠 1 組。',
      'promotions.section.discount_summary.content':
        '{{ criteria }}{{ benefits }}',
      'promotions.section.discount_percentage.caption': '{{ value }}折',

      'promotions.page.term.title': '條款與細則',
      'promotions.page.term.confirm': '確認',
      'promotions.page.term.default':
        '＊商品活動優惠以進入購物車結帳計算為準\n＊結帳時請選擇活動指定付款與送貨方式以取得優惠\n＊若活動僅適用會員，結帳時請登入以取得優惠',
      'promotions.page.start_at':
        '優惠活動開始日期 <span class="local-datetime">{{date}}</span>',
      'promotions.page.end_at':
        '優惠至 <b class="local-datetime">{{date}}</b> 截止',
      'promotions.page.redeem_gift.start_at':
        '活動開始日期 <span class="local-datetime">{{date}}</span>',
      'promotions.page.redeem_gift.end_at':
        '活動至 <b class="local-datetime">{{date}}</b> 截止',
      'promotions.page.display_local_timezone':
        '依據你的當地時區顯示<br/>(GMT{{offset}})',

      'promotions.exclude.items.hint.title': '已是最優惠價格，不參與:',
      'promotions.exclude.items.hint.order_discount': '全店折扣優惠',
      'promotions.exclude.items.hint.member_tier': '會員默認優惠',
      'promotions.exclude.items.hint.user_credit': '購物金折抵',
      'promotions.exclude.items.hint.member_point': '點數折現',

      'product.page.title.product-info': '商品',
      'product.page.quantity': '數量',
      'product.page.unit_price': '單件價格',
      'product.page.subtotal': '小計',
      'product.page.add_to_cart_count':
        '已加入 <span class="order-detail-count">{{count}}</span> 項商品',

      'products.quick_cart.quantity': '數量',
      'products.quick_cart.preorder_now': '現在預購',
      'products.quick_cart.add_to_cart': '加入購物車',
      'products.quick_cart.quantity_invalid':
        '該數量不適用，請填入有效的數量。',
      'products.quick_cart.out_of_stock': '售完',
      'products.quick_cart.out_of_stock_hint': '商品存貨不足，未能加入購物車',
      'products.quick_cart.out_of_number_hint': '商品數量不足',
      'products.quick_cart.low_stock_hint': '您所填寫的商品數量超過庫存',
      'products.quick_cart.insufficient_point': '會員點數不足，請移除部分贈品',
      'products.quick_cart.reached_max_purchase_quantity':
        '商品購買上限為 {{ message }} 件',
      'products.quick_cart.quantity_of_stock_hint':
        '現庫存只剩下 {{message}} 件',
      'products.quick_cart.messagetobuy': '若想購買，請聯絡我們。',
      'products.quick_cart.message': '聯絡我們',
      'products.quick_cart.show_more': '商品資訊',
      'products.message.title': '確定要移除這個商品嗎？',
      'products.message.button_cancel': '取消',
      'products.message.button_ok': '確定',
      'products.purchase_limit_hint': '每位顧客限購 {{limit}} 件',
      'purchase_limit_hint.purchasable':
        '每位顧客限購 {{limit}} 件，您只能再購買 {{purchasable_qty}} 件',
      'products.purchase_limit_hint_can_not_purchase':
        '每位顧客限購 {{limit}} 件，您已購買達上限',

      'products.category.advance_filter.title': '篩選',
      'products.category.advance_filter.clear': '清除',
      'products.category.advance_filter.clear_all': '清除全部',
      'products.category.advance_filter.apply': '套用篩選',
      'products.category.advance_filter.apply.hint': '請先選擇篩選內容',
      'products.category.advance_filter.show_more': '看更多',
      'products.category.advance_filter.show_less': '收起內容',
      'products.category.advance_filter.type.color': '顏色',
      'products.category.advance_filter.type.size': '尺寸',
      'products.category.advance_filter.type.brand': '品牌',
      'products.category.advance_filter.type.material': '材質',
      'products.category.advance_filter.type.price': '價格 ({{ currency }})',
      'products.category.advance_filter.tag.price_range':
        '{{ min_price }} ~ {{ max_price }}',
      'products.category.advance_filter.tag.min_price': '≥ {{ min_price }}',
      'products.category.advance_filter.tag.max_price': '≤ {{ max_price }}',
      'products.category.advance_filter.placeholder.min_price': '最小金額',
      'products.category.advance_filter.placeholder.max_price': '最大金額',

      'products.variant_selector.hide': '收起',
      'products.variant_selector.view_other_variations':
        '看其他 {{count}} 個選項',

      'hk_sfplus.region': '地區',
      'hk_sfplus.area': '範圍',
      'hk_sfplus.district': '區域',
      'hk_sfplus.address': '{{region}} {{district}} {{area}}',

      'zeek.station.address': '自取點地址',
      'zeek.station.name': '自取點名稱',
      'zeek.station.provider': '自取服務商',
      'zeek.pickup.region': '自取地區',
      'zeek.pickup.area': '自取區域',

      'tcat.time_slot.title': '選擇指定到貨時段',
      'tcat.time_slot.01': '13:00 以前',
      'tcat.time_slot.02': '14:00 - 18:00',
      // 03 key is no longer used according to tcat spec
      'tcat.time_slot.04': '任何時段',

      'member_referral.discount': '會員推薦優惠',
      'member_referral.code.copy_and_share':
        '複製並分享連結給朋友，推薦對象和你將獲得購物金。',
      'member_referral.code.remarks': '活動規則說明',
      'member_referral.generate.code': '產生推薦連結',
      'member_referral.generate.code.success': '產生成功',
      'member_referral.copy.link': '複製連結',
      'member_referral.copy.link.success': '複製成功',
      'member_referral.welcome_credit.popup.title':
        '恭喜，你獲得 {{reward_value}} 購物金',
      'member_referral.welcome_credit.popup.content':
        '透過朋友分享，你獲得了 {{reward_value}} 購物金，可在結帳時使用。成為會員的你也可以分享給新朋友，並獲得推薦優惠喔。',
      'member_referral.welcome_credit.popup.continue': '知道並繼續購物',

      'lock_inventory.tooltip':
        '保留至 {{expired_time}}\n({{start_time}}開始的直播:{{event_name}})\n根據你的當地時區顯示（GMT{{timezone}}）',

      'store_stock.loading': '確認庫存中',
      'store_stock.take_a_while': '這會需要一些時間...',
      'store_stock.current_availability': '目前門市供貨狀況',
      'store_stock.description':
        '商品販售以現場狀況為準，僅為完成預訂之顧客保留商品庫存，建議您來店前先完成下單並確認店取日期。',
      'store_stock.available': '可於線上購買，門市取貨',
      'store_stock.sold_out': '售完',

      'return_order.popup.failed.title': '退貨申請失敗！',
      'return_order.popup.failed.content':
        '此物流方式訂單金額上限為 {{ amount }} 台幣，請重新更換物流方式再次送出訂單退貨的申請。',
      'return_order.popup.failed.confirm': '確定',
      'verification.email.trial_merchant.reach_limit.failure':
        '商店試營運中，無法發送驗證郵件，如有需求請聯繫商店。',
    });
  },
]);
