export const EVENT_NAME_FOR_ARCHIVE_ORDERS = {
  ORDER_NUMBER_LINK_CLICK: 'OrderNumberLinkClick',
};

export const EVENT_POSITION_FOR_ARCHIVE_ORDERS = {
  MEMBER_CENTER_CREDIT_HISTORY: 'membercenter_credit_history',
  MEMBER_CENTER_POINT_HISTORY: 'membercenter_point_history',
};

export const EVENT_SCOPE_FOR_ARCHIVE_ORDERS = {
  CRM: 'CRM',
};

export const EVENT_CREATED_AT_FORMAT = 'YYYY-MM-DD HH:mm:ss';
