import { isEmpty } from 'lodash-es';

export const parseCheckoutObjectToOrder = (checkoutObject) => {
  const {
    orders = [],
    total,
    subtotal,
    user_credit,
    payment_fee,
    delivery_fee,
    total_tax_fee,
    cart_attributes,
    checkout_object_discount,
  } = checkoutObject;

  const [firstOrder = {}] = orders;

  const _id = firstOrder._id;
  const order_number = firstOrder.order_number;
  const order_ids = orders.map((order) => order._id);
  const subtotal_items = orders.reduce(
    (items, { subtotal_items = [] }) => [...items, ...subtotal_items],
    [],
  );

  return {
    _id,
    order_number,
    total,
    subtotal,
    user_credit,
    total_tax_fee,
    subtotal_items,
    cart_attributes,
    order_delivery: delivery_fee,
    order_payment: { payment_fee },
    order_discount: checkout_object_discount,
    order_ids: isEmpty(order_ids) ? undefined : order_ids,
  };
};
