app.directive('smsMessengerCheckbox', [
  '$q',
  'mainConfig',
  'benchatService',
  'smsSubscriptionService',
  function ($q, mainConfig, benchatService, smsSubscriptionService) {
    return {
      restrict: 'A',
      templateUrl: require('../../../../../public/themes/v1/default/views/templates.sms-messenger-checkbox.html'),
      scope: {
        smsMessengerCheckbox: '=',
        customerId: '@',
        topicStr: '@',
        benchatSubscriptions: '=',
        decorateHandleClick: '=',
      },
      link: function (scope) {
        scope.sms = scope.smsMessengerCheckbox;
        scope.customerIdentifier = scope.customerId;
        scope.userRef = benchatService.generateUserRef(
          scope.customerIdentifier,
        );
        scope.topics = scope.topicStr.split(',');
        scope.subscriptions = []; // would only store active subscriptions

        const getSubscriptions = function () {
          return benchatService
            .getSubscriptions(scope.customerIdentifier)
            .then(function (result) {
              const subscriptions = result.data.subscriptions;
              // to update parent`s benchat subscription
              if (subscriptions.length > 0) {
                scope.benchatSubscriptions = angular.extend(
                  scope.benchatSubscriptions,
                  subscriptions,
                );
              }
              scope.subscriptions = smsSubscriptionService.getActiveSubscriptions(
                scope.topics,
                subscriptions,
              );
              scope.sms.isChecked = scope.subscriptions.length > 0;
            });
        };

        const deactivateSubscription = function () {
          // Keep this condition temporarily until subscription data migration finished
          if (scope.subscriptions.length === 0) {
            // for deactivate an not exist subscription
            return createInactiveSubscription();
          }

          const tasks = scope.subscriptions.map(function (subscription) {
            return benchatService.deactivateSubscription(subscription._id);
          });

          return $q.all(tasks).finally(function () {
            return getSubscriptions();
          });
        };

        const createInactiveSubscription = function () {
          return smsSubscriptionService
            .createInactiveSubscriptions(
              scope.customerIdentifier,
              mainConfig.merchantData._id,
              scope.userRef,
              scope.topics,
            )
            .then(function () {
              return getSubscriptions();
            });
        };

        const createActiveSubscription = function () {
          return smsSubscriptionService
            .createActiveSubscriptions(
              scope.customerIdentifier,
              mainConfig.merchantData._id,
              scope.userRef,
              scope.topics,
            )
            .then(function () {
              return getSubscriptions();
            });
        };

        scope.handleClick = function () {
          if (scope.sms.isChecked === false) {
            deactivateSubscription();
          } else {
            createActiveSubscription();
          }
        };

        const init = function () {
          getSubscriptions();
        };

        init();
      },
    };
  },
]);
