import { isEmpty } from 'lodash-es';

app.service('productStockService', [
  'slFeatureService',
  'productService',
  'productSetService',
  'productPreorderService',
  function (
    slFeatureService,
    productService,
    productSetService,
    productPreorderService,
  ) {
    function checkStockPromise({
      product,
      selectedProductSetData,
      variationId,
      isFastCheckoutCart,
    }) {
      if (productSetService.isProductSetRevampEnabled(product)) {
        return productService.checkProductSetStock({
          id: product._id,
          productSetData: selectedProductSetData,
        });
      } else if (productSetService.isProductSetEnabled(product)) {
        return productService.checkProductSetStock({
          id: product._id,
          productSetData: productSetService.generateProductSetParams(
            selectedProductSetData,
          ),
        });
      } else {
        return productService.checkStock(
          product._id,
          variationId,
          null,
          isFastCheckoutCart,
        );
      }
    }
    function getProductStockInfo(product, variationSelected) {
      const selectedProduct = variationSelected || product;
      const preorderLimit = product.is_preorder
        ? selectedProduct.preorder_limit
        : 0;
      return {
        is_preorder: product.is_preorder,
        unlimited_quantity: product.unlimited_quantity,
        out_of_stock_orderable: product.out_of_stock_orderable,
        quantity: selectedProduct.quantity,
        cart_quantity: selectedProduct.cart_quantity || 0,
        preorder_limit: preorderLimit,
      };
    }
    function checkHasStock(product, variationSelected) {
      const productStockInfo = getProductStockInfo(product, variationSelected);
      return (
        productStockInfo.quantity > 0 ||
        product.unlimited_quantity ||
        productPreorderService.isPreorderOrderable(productStockInfo) ||
        productService.isOutOfStockOrderable(productStockInfo)
      );
    }
    function isOutOfStock(productStockInfo) {
      return !checkHasStock(productStockInfo);
    }

    function handleQuantity(quantityOfStock) {
      return quantityOfStock >= 0 && quantityOfStock <= 10;
    }

    function showStockQuantityHint({
      product,
      variationSelected,
      checkoutReady,
      quantityOfStock,
    }) {
      const productStockInfo = getProductStockInfo(product, variationSelected);
      const hideWithOutOfStockOrderable =
        !slFeatureService.hasFeature('preorder_limit') &&
        productStockInfo.out_of_stock_orderable;
      return (
        !productStockInfo.unlimited_quantity &&
        productStockInfo.quantity > 0 &&
        !hideWithOutOfStockOrderable &&
        checkoutReady &&
        angular.isDefined(quantityOfStock) &&
        handleQuantity(quantityOfStock) &&
        !productSetService.isProductSetRevampEnabled(product)
      );
    }

    function getHasStockVariant(product) {
      if (isEmpty(product.variations)) {
        return;
      }
      return product.variations.find((variation) => {
        return checkHasStock(product, variation);
      });
    }

    return {
      checkStockPromise,
      checkHasStock,
      isOutOfStock,
      getProductStockInfo,
      showStockQuantityHint,
      getHasStockVariant,
    };
  },
]);
